import * as React from 'react';
import {
  AlertGroup,
  AlertVariant,
  Alert,
  Button,
  PageSection,
  Split,
  SplitItem,
  Title,
  Breadcrumb,
  BreadcrumbItem,
  Modal,
  Form,
  FormGroup,
  FormHelperText,
  FormAlert,
  TextInput,
  TextArea,
  ActionGroup,
  ModalVariant,
  FormSelect,
  FormSelectOption,
} from '@patternfly/react-core';
import { Link } from 'react-router-dom';
import { sortable, headerCol } from '@patternfly/react-table';
import { ActionList } from '@app/lib/ActionList';
import { AuthContext } from '@app/lib/AuthProvider';
import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import {
  CreateOrganizationRequest,
  UpdateOrganizationRequest,
  GetOrganizationsResponse,
  Member,
  member_RoleToJSON,
  member_StateToJSON,
  userStateToJSON,
  accessModeToJSON,
  AccessMode,
  RequestOrganizationMembershipRequest,
  MembershipType,
  DeleteOrganizationRequest,
  GetEntityTypeConfigurationsResponse,
} from '@mergetb/api/portal/v1/workspace_types';
import { useFetch } from 'use-http';

const Organizations: React.FunctionComponent = () => {
  const { api } = React.useContext(GeneralSettingsContext);
  const { identity } = React.useContext(AuthContext);
  const username = identity?.traits.username;

  const userview_last = (localStorage.getItem('userview') ?? true) == true;
  const [viewLabel, setViewLabel] = React.useState('View ' + (userview_last ? 'All' : 'Own'));
  const [userView, setUserView] = React.useState(userview_last);
  const [alerts, setAlerts] = React.useState([]);
  const [reload, setReload] = React.useState(1);

  const [showNew, setShowNew] = React.useState(false);
  const [newOrgName, setNewOrgName] = React.useState({ value: '', valid: 'error' });
  const [newOrgDesc, setNewOrgDesc] = React.useState('');
  const [invalidName, setInvalidName] = React.useState('');

  const [orgCat, setOrgCat] = React.useState('');
  const [orgSubCat, setOrgSubCat] = React.useState('');
  const [orgSubCats, setOrgSubCats] = React.useState(Array<string>);
  const [otherSubCat, setOtherSubCat] = React.useState('');

  const options = {
    credentials: 'include',
    cachePolicy: 'no-cache',
  };

  const { data: entTypeData } = useFetch(api + '/configurations/entitytype', options, []);
  const columns = [
    { title: 'Name', cellTransforms: [headerCol()], transforms: [sortable] },
    { title: 'Description', cellTransforms: [headerCol()], transforms: [sortable] },
    { title: 'State', cellTransforms: [headerCol()], transforms: [sortable] },
    { title: 'Access Mode', cellTransforms: [headerCol()], transforms: [sortable] },
    { title: 'Members', cellTransforms: [headerCol()], transforms: [sortable] },
    { title: 'Projects', cellTransforms: [headerCol()], transforms: [sortable] },
    { title: 'Category', cellTransforms: [headerCol()], transforms: [sortable] },
    { title: 'Subcategory', cellTransforms: [headerCol()], transforms: [sortable] },
  ];

  const entityTypes = React.useMemo(() => {
    if (entTypeData) {
      if (Object.prototype.hasOwnProperty.call(entTypeData, 'Types')) {
        const types = GetEntityTypeConfigurationsResponse.fromJSON(entTypeData).Types;
        // Force first category choice in GUI
        if (types.length > 0) {
          setOrgCat(types[0].etype);
          return types;
        }
      }
    }
    return undefined;
  }, [entTypeData]);

  const setMode = (oid, mode) => {
    const req: UpdateOrganizationRequest = {
      name: oid,
      accessMode: {
        value: mode,
      },
    };

    fetch(api + '/organization/' + oid, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(req),
    })
      .then((resp) => {
        if (resp.ok) {
          addAlert('Organization ' + oid + ' access mode set to ' + accessModeToJSON(mode), 'success');
          setReload(reload + 1);
        }
        if (resp.status !== 200) {
          addAlert('Error: ' + resp.statusText, 'danger');
        }
        return;
      })
      .catch((error) => {
        console.log(error);
        addAlert(error.message, 'danger');
      });
  };

  const deleteOrg = (org: string) => {
    const req: DeleteOrganizationRequest = {
      user: username,
      name: org,
    };
    fetch(api + '/organization/' + org, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(req),
    })
      .then((resp) => {
        if (resp.ok) {
          addAlert('Organization ' + org + ' deleted.', 'success');
          setReload(reload + 1);
        }
        if (resp.status !== 200) {
          addAlert('Error: ' + resp.statusText, 'danger');
        }
        return;
      })
      .catch((error) => {
        console.log(error);
        addAlert(error.message, 'danger');
      });
  };

  const reqMembership: (oid: string, uid: string) => void = (oid, uid) => {
    const req: RequestOrganizationMembershipRequest = {
      organization: oid,
      id: uid,
      kind: MembershipType.UserMember,
    };

    fetch(api + '/organization/' + oid + '/member/' + uid, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(req),
    })
      .then((resp) => {
        if (resp.ok) {
          addAlert('Membership in ' + oid + ' requested for ' + uid, 'success');
          setReload(reload + 1);
        }
        if (resp.status !== 200) {
          addAlert('Error: ' + resp.statusText, 'danger');
        }
        return;
      })
      .catch((error) => {
        console.log(error);
        addAlert(error.message, 'danger');
      });
  };

  const actions = [
    {
      title: 'Request User Membership',
      onClick: (e, rowId, rowData) => {
        const oid = rowData.name.props.text;
        reqMembership(oid, username);
      },
    },
    {
      isSeparator: true,
    },
    {
      title: 'Set Access Public',
      onClick: (event, rowId, rowData) => {
        const oid = rowData.name.props.text;
        setMode(oid, AccessMode.Public);
      },
    },
    {
      title: 'Set Access Protected',
      onClick: (event, rowId, rowData) => {
        const oid = rowData.name.props.text;
        setMode(oid, AccessMode.Protected);
      },
    },
    {
      title: 'Set Access Private',
      onClick: (event, rowId, rowData) => {
        const oid = rowData.name.props.text;
        setMode(oid, AccessMode.Private);
      },
    },
    {
      isSeparator: true,
    },
    {
      title: 'Delete',
      onClick: (event, rowId, rowData) => {
        deleteOrg(rowData[0].props.text);
      },
    },
  ];

  const mapper = (json) => {
    const orgs: GetOrganizationsResponse = GetOrganizationsResponse.fromJSON(json);
    return orgs.organizations.map((x) => [
      {
        title: <Link to={'/organization/' + x.name}>{x.name}</Link>,
        props: {
          component: 'th',
          text: x.name,
        },
      },
      x.description,
      userStateToJSON(x.state),
      accessModeToJSON(x.accessMode),
      <>
        {Object.keys(x.members).map((m, i) => {
          return (
            <>
              <Link key={i} to={'/user/' + m}>
                {m}
              </Link>
              <br />
            </>
          );
        })}
      </>,
      <>
        {Object.keys(x.projects).map((p) => (
          <>
            <Link to={'/project/' + p}>{p}</Link>
            <br />
          </>
        ))}
      </>,
      x.category,
      x.subcategory,
    ]);
  };

  const onOrgNameChange = (val) => {
    const re = /^[a-z]([a-z0-9]*[a-z0-9])?$/;
    if (re.test(val) === false) {
      setNewOrgName({ value: val, valid: 'error' });
      setInvalidName(
        'The organization name must start with a lowercase and only contain lowercase alphanumeric characters'
      );
    } else {
      setInvalidName('');
      setNewOrgName({ value: val, valid: val === '' ? 'error' : 'success' });
    }
  };

  const toggleNewOrg = () => {
    setShowNew(!showNew);
  };

  const toggleView = () => {
    setViewLabel('View ' + (userView === false ? 'All' : 'Own'));
    localStorage.setItem('userview', userView ? '0' : '1');
    setUserView(!userView);
    setReload(reload + 1);
  };

  const addAlert = (t, v) => {
    setAlerts((prev) => [...prev, { title: t, variant: v }]);
  };

  const crumbs = (
    <PageSection>
      <Breadcrumb>
        <BreadcrumbItem>Organizations</BreadcrumbItem>
      </Breadcrumb>
    </PageSection>
  );

  const header = (
    <PageSection>
      <Split>
        <SplitItem>
          <Title headingLevel="h1" size="lg">
            Organizations
          </Title>
        </SplitItem>
        <SplitItem isFilled />
        <SplitItem>
          <Button variant="control" aria-label={viewLabel} onClick={toggleView}>
            {viewLabel}
          </Button>
          <Button variant="control" aria-label="New Organization" onClick={toggleNewOrg}>
            New Organization
          </Button>
        </SplitItem>
      </Split>
    </PageSection>
  );

  const submitForm = () => {
    const members = {};
    members[username] = { State: 'Active', Role: 'Creator' };

    const req: CreateOrganizationRequest = {
      user: username,
      organization: {
        name: newOrgName.value,
        description: newOrgDesc,
        category: orgCat,
        subcategory: otherSubCat !== '' ? otherSubCat : orgSubCat,
      },
    };

    fetch(api + '/organization/' + newOrgName.value, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(req),
    })
      .then((resp) => {
        if (resp.ok) {
          addAlert('New Organization ' + newOrgName.value + ' Created', 'success');
          setReload(reload + 1);
          setNewOrgDesc('');
          setNewOrgName('');
          toggleNewOrg();
          return;
        } else {
          return resp.text().then((text) => {
            throw new Error(text);
          });
        }
      })
      .catch((error) => {
        console.log(error);
        addAlert(error.message, 'danger');
      });
  };

  const handleOrgCatChange = (e) => {
    setOrgCat(e);
    if (entityTypes) {
      const found = entityTypes.find((o) => o.etype === e);
      if (found && found.subtypes) {
        setOrgSubCats(found.subtypes);
      }
    }
  };

  const notready =
    newOrgName.value === '' || newOrgDesc === '' || orgCat === '' || (orgSubCat === 'Other' && otherSubCat === '');

  const newModal = (
    <Modal isOpen={showNew} onClose={toggleNewOrg} variant={ModalVariant.medium} aria-label="New Organization">
      <React.Fragment>
        <Title headingLevel="h1" size="2xl">
          New Organization
        </Title>
        <Form>
          {notready && (
            <FormAlert>
              <Alert variant="danger" title="All fields must be filled" aria-live="polite" isInline />
            </FormAlert>
          )}
          <FormGroup
            label="Name"
            fieldId="name"
            helperText={<FormHelperText isHidden={newOrgName.valid !== 'success'} />}
            helperTextInvalid={invalidName}
          >
            <TextInput type="text" id="name" value={newOrgName.value} onChange={(e) => onOrgNameChange(e)} />
          </FormGroup>
          <FormGroup label="Description">
            <TextArea type="text" id="name" value={newOrgDesc} autoResize={true} onChange={(e) => setNewOrgDesc(e)} />
          </FormGroup>
          <FormGroup label="Category">
            <FormSelect value={orgCat} onChange={handleOrgCatChange} aria-label="Category">
              {entityTypes && entityTypes.map((e, i) => <FormSelectOption key={i} value={e.etype} label={e.etype} />)}
            </FormSelect>
          </FormGroup>
          {orgSubCats.length !== 0 && (
            <React.Fragment>
              <FormGroup label="Subcategory">
                <FormSelect value={orgSubCat} onChange={(e) => setOrgSubCat(e)} aria-label="Subcategory">
                  {orgSubCats.map((e, i) => (
                    <FormSelectOption key={i} value={e} label={e} />
                  ))}
                </FormSelect>
              </FormGroup>
            </React.Fragment>
          )}
          {orgSubCat === 'Other' && (
            <React.Fragment>
              <FormGroup label="Other Subcategory">
                <TextInput
                  isRequired
                  type="text"
                  id="othersubcat"
                  value={otherSubCat}
                  onChange={(e) => setOtherSubCat(e)}
                />
              </FormGroup>
            </React.Fragment>
          )}
          <ActionGroup>
            <Button variant="control" onClick={submitForm} isDisabled={notready} isAriaDisabled={notready}>
              Submit
            </Button>
          </ActionGroup>
        </Form>
      </React.Fragment>
    </Modal>
  );

  const notifications = (
    <AlertGroup isToast>
      {alerts.map((a, i) => (
        <Alert variant={AlertVariant[a.var]} title={a.title} timeout={3000} key={i} />
      ))}
    </AlertGroup>
  );

  let url = api + '/organization';
  if (userView === false) {
    url += '?filter=ByAll';
  }

  return (
    <React.Fragment>
      {alerts.length !== 0 && notifications}
      {newModal}
      {crumbs}
      {header}
      <PageSection>
        <ActionList
          kind="Organizations"
          columns={columns}
          url={url}
          actions={actions}
          mapper={mapper}
          reload={reload}
        />
      </PageSection>
    </React.Fragment>
  );
};

export { Organizations };

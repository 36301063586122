import * as React from 'react';
import { AuthContext } from '@app/lib/AuthProvider';
import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import {
  Avatar,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  Button,
  Icon,
  Modal,
  ModalVariant,
} from '@patternfly/react-core';
import UserIcon from '@patternfly/react-icons/dist/esm/icons/user-icon';
import { Link, useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';
import ory from '@app/lib/OrySdk';
import { GetUserResponse } from '@mergetb/api/portal/v1/workspace_types';
import { useFetch } from 'use-http';
import ExclamationCircleIcon from '@patternfly/react-icons/dist/esm/icons/exclamation-circle-icon';

type AccountMenuProps = {};

const AccountMenu: React.FunctionComponent<AccountMenuProps> = () => {
  const { api } = React.useContext(GeneralSettingsContext);
  const { isAuthenticated, identity, setAdmin, isAdmin, user, setUser } = React.useContext(AuthContext);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const [userInactive, setUserInactive] = React.useState<boolean>(false);

  const options = { credentials: 'include', cachePolicy: 'no-cache' };
  const logout = CreateLogoutHandler([isAuthenticated]);
  const history = useHistory();

  // const user = React.useMemo(() => {
  //   if (data) {
  //     if (Object.prototype.hasOwnProperty.call(data, 'code') && data.code === 9) {
  //       if (Object.prototype.hasOwnProperty.call(data, 'message') && data.message === 'User Inactive') {
  //         setUserInactive(true);
  //         return undefined;
  //       }
  //     }
  //     if (Object.prototype.hasOwnProperty.call(data, 'user')) {
  //       const u = GetUserResponse.fromJSON(data).user;
  //       if (u?.name == '') {
  //         history.push('/userinfo');
  //       }
  //       return u;
  //     }
  //   }
  //   return undefined;
  // }, [data, error]);

  const dropdownItems = [
    <DropdownItem key="settings" component={<Link to="/settings">Update Identity Profile</Link>} />,
    <DropdownItem key="userupdate" component={<Link to={'/updateuserinfo'}>Update Portal User Profile</Link>} />,
    <DropdownItem
      key="logout"
      component={
        <Button onClick={logout} variant="link">
          Logout
        </Button>
      }
    />,
  ];

  const icon = React.useMemo(() => {
    if (identity?.traits.picture) {
      return <Avatar src={identity?.traits.picture} alt="avatar" size="md" />;
    }

    return <UserIcon />;
  }, [identity]);

  const reddot = React.useMemo(() => {
    if (isAdmin) {
      return (
        <Icon status="danger">
          <ExclamationCircleIcon />
        </Icon>
      );
    }
  }, [isAdmin]);

  // read User data and set admin in auth context.
  React.useEffect(() => {
    if (identity === undefined || identity.traits.username === '' || user !== undefined) {
      return;
    }

    // const controller = new AbortController();

    fetch(api + '/user/' + identity.traits.username, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      // signal: controller.signal,
    })
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((data) => {
            const resp = GetUserResponse.fromJSON(data);
            if (resp.user !== undefined) {
              setAdmin(resp.user.admin);
              setUser(resp.user);
            }
          });
        } else if (resp.status === 400) {
          resp.json().then((data) => {
            if (Object.prototype.hasOwnProperty.call(data, 'code') && data.code === 9) {
              if (Object.prototype.hasOwnProperty.call(data, 'message') && data.message === 'User Inactive') {
                setUserInactive(true);
              }
            }
          });
        }
      })
      .catch((err) => {
        console.log('fetch user data error', err);
      });

    // return () => controller.abort();
  }, [setAdmin, isAuthenticated, identity?.traits.username, api, setUser, user]);

  const userInactiveModal = (
    <Modal
      isOpen={userInactive}
      variant={ModalVariant.medium}
      title="User Inactive"
      aria-label={'User Inactive'}
      onClose={logout}
      actions={[
        <Button key="confirm" variant="primary" onClick={logout}>
          Logout
        </Button>,
      ]}
    >
      Your account is currently not active. Please ask a testbed administrator or an organization administrator (of
      which you are a member) to approve your account.
    </Modal>
  );

  return (
    <React.Fragment>
      {userInactive && userInactiveModal}
      {isAuthenticated ? (
        <Dropdown
          onSelect={() => setIsOpen(!isOpen)}
          isOpen={isOpen}
          isPlain
          isFullHeight
          position={'right'}
          toggle={
            <DropdownToggle id="user-settings-dropdown" onToggle={(next) => setIsOpen(next)} icon={icon}>
              {identity?.traits.username}
            </DropdownToggle>
          }
          dropdownItems={dropdownItems}
        />
      ) : (
        <Link to="/login">Login</Link>
      )}
      {reddot}
    </React.Fragment>
  );
};

// Return a function that logs the user out.
const CreateLogoutHandler: React.FC = (deps?: React.DependencyList) => {
  const [logoutToken, setLogoutToken] = React.useState<string>('');
  const history = useHistory();
  const { setSession } = React.useContext(AuthContext);

  React.useEffect(() => {
    let mounted = true;
    ory
      .createBrowserLogoutFlow()
      .then(({ data }) => {
        if (mounted) {
          setLogoutToken(data.logout_token);
        }
      })
      .catch((err: AxiosError) => {
        switch (err.response?.status) {
          case 401:
            // do nothing, the user is not logged in
            return;
        }

        // Something else happened!
        return Promise.reject(err);
      });

    return () => {
      mounted = false;
    };
  }, deps);

  return () => {
    if (logoutToken) {
      ory.updateLogoutFlow({ token: logoutToken }).then(() => {
        setSession(undefined);
        history.push('/login');
        history.go(0);
      });
    }
  };
};

export { AccountMenu };

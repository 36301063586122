import * as React from 'react';
import {
  Alert,
  AlertGroup,
  AlertVariant,
  PageSection,
  Title,
  Form,
  FormGroup,
  FormAlert,
  FormHelperText,
  FormSelect,
  FormSelectOption,
  ActionGroup,
  Button,
  TextInput,
  Split,
  SplitItem,
  Modal,
  ModalVariant,
  Breadcrumb,
  BreadcrumbItem,
} from '@patternfly/react-core';
import { ActionList } from '@app/lib/ActionList';
import { Link } from 'react-router-dom';
import { sortable, headerCol } from '@patternfly/react-table';
import { FetchSelect } from '@app/lib/FetchSelect';
import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import { AttachXDCRequest, xDCTypeFromJSON } from '@mergetb/api/portal/v1/xdc_types';

type XDCProps = {
  fxid: string | undefined;
  fpid: string | undefined;
  filter: boolean;
};

const XDCs: React.FunctionComponent<XDCProps> = ({ fxid, fpid, filter }) => {
  const [reload, setReload] = React.useState(false);

  const conf = React.useContext(GeneralSettingsContext);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [xdc, setXdc] = React.useState({ value: '', valid: 'error' });
  const [invalidXDC, setInvalidXDC] = React.useState('');
  const [pid, setPid] = React.useState({ value: '', valid: 'error' });
  const [xdctype, setXDCType] = React.useState('shared');
  const [alerts, setAlerts] = React.useState([]);

  // attach dialog
  const [attachIsOpen, setAttachIsOpen] = React.useState(false);
  const [attachTo, setAttachTo] = React.useState('');
  const [attachXDC, setAttachXDC] = React.useState('');

  const columns = [
    { title: 'Name', cellTransforms: [headerCol()], transforms: [sortable] },
    { title: 'Project', cellTransforms: [headerCol()], transforms: [sortable] },
    { title: 'Type', cellTransforms: [headerCol()], transforms: [sortable] },
    { title: 'Attached', cellTransforms: [headerCol()], transforms: [sortable] },
    { title: 'URL', cellTransforms: [], transforms: [sortable] },
    { title: 'SSH Name', cellTransforms: [headerCol()], transforms: [sortable] },
    { title: 'Creator', cellTransforms: [headerCol()], transforms: [sortable] },
    { title: 'Memory Limit', cellTransforms: [headerCol()], transforms: [sortable] },
    { title: 'CPU Limit', cellTransforms: [headerCol()], transforms: [sortable] },
    { title: 'Image', cellTransforms: [headerCol()], transforms: [sortable] },
  ];

  const fcolumns = [
    { title: 'Name' },
    { title: 'Project' },
    { title: 'Attached' },
    { title: 'URL' },
    { title: 'SSH Name' },
    { title: 'Creator' },
    { title: 'Memory Limit' },
    { title: 'CPU Limit' },
    { title: 'Image' },
  ];

  const actions = [
    {
      title: 'Attach',
      onClick: (event, rowId, rowData) => {
        setAttachXDC(rowData.name.title.props.text + '.' + rowData.project.title.props.text);
        setXDCType(rowData.type.title);
        setAttachIsOpen(true);
      },
    },
    {
      title: 'Detach',
      onClick: (event, rowId, rowData) => {
        const xdc = rowData.name.title.props.text;
        const mtz = rowData.attached.title.props.text;
        const pid = rowData.project.title.props.text;
        const [rid, eid, _] = mtz.split('.');

        fetch(conf.api + '/xdc/attach/' + xdc + '/' + pid, {
          method: 'DELETE',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            experiment: eid,
            realization: rid,
          }),
        })
          .then((resp) => {
            if (resp.ok) {
              setReload(!reload);
              addAlert('XDC ' + xdc + ' detached from ' + mtz, 'success');
            }
          })
          .catch((error) => {
            addAlert(error.message, 'danger');
          });
      },
    },
    {
      title: 'Delete',
      onClick: (event, rowId, rowData) => {
        const xdc = rowData.name.title.props.text;
        const pid = rowData.project.title.props.text;
        fetch(conf.api + '/xdc/instance/' + xdc + '/' + pid, {
          method: 'DELETE',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            xdc: xdc,
            project: pid,
          }),
        })
          .then((resp) => {
            if (resp.ok) {
              setReload(!reload);
              addAlert('XDC deleted: ' + xdc, 'success');
            }
          })
          .catch((error) => {
            console.log(error);
            addAlert(error.message, 'danger');
          });
      },
    },
  ];

  let xdcrow = (x) => {
    const [xid, pid] = x.name.split('.');
    const [mrid, meid, mpid] = x.materialization.split('.');

    return [
      // name
      {
        title: (
          <Link to={`/xdcs/${pid}/${xid}`} text={xid}>
            {xid}
          </Link>
        ),
      },

      // project
      {
        title: (
          <Link to={`/project/${pid}`} text={pid}>
            {pid}
          </Link>
        ),
      },

      // type
      x.type,

      // attached
      {
        title: (
          <Link to={'/materializations/' + mpid + '/' + meid + '/' + mrid} text={x.materialization}>
            {x.materialization}
          </Link>
        ),
      },

      // juypter URL
      {
        title: (
          <a href={x.url} target="_blank">
            Jupyter
          </a>
        ),
      },

      // SSH Name
      x.fqdn.split('.')[0],

      // creator
      x.creator,

      // mem limit
      x.memlimit,

      // cpu limit
      x.cpulimit,

      // image
      x.image,
    ];
  };

  let mapper = (json) => {
    return json.XDCs.map(xdcrow);
  };

  let fmapper = (json) => {
    return [xdcrow(json.xdc)];
  };

  const addAlert = (t, v) => {
    setAlerts((prev) => [...prev, { title: t, variant: v }]);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const submitForm = () => {
    fetch(conf.api + '/xdc/instance/' + xdc.value + '/' + pid.value, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        xdc: xdc.value,
        project: pid.value,
        type: xDCTypeFromJSON(xdctype),
      }),
    })
      .then((response) => {
        if (response.ok) {
          setReload(!reload);
          toggleModal();
          addAlert('XDC created: ' + xdc.value, 'success');
          return;
        } else {
          return response.text().then((text) => {
            throw new Error(text);
          });
        }
      })
      .catch((error) => {
        console.log(error);
        addAlert(error.message, 'danger');
      });
  };

  const submitAttach = () => {
    // "attachXDC" is the xdc name to attach to mtz "attachTo"
    // xdc.project ==> rlz.eid.pidS
    const xdcTkns = attachXDC.split('.');
    const mtzTkns = attachTo.split('.');

    const req = {
      xdc: attachXDC,
      project: xdcTkns[1],
      realization: mtzTkns[0],
      experiment: mtzTkns[1],
      realizationProject: mtzTkns[2],
    };

    console.log('attach request', JSON.stringify(req));

    fetch(conf.api + '/xdc/attach/' + xdcTkns[0] + '/' + xdcTkns[1] + '/' + mtzTkns[1] + '/' + mtzTkns[0], {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(req),
    })
      .then((resp) => {
        if (resp.ok) {
          setAttachIsOpen(false);
          setAttachXDC('');
          setAttachTo('');
          setReload(!reload);
          addAlert('XDC ' + attachXDC + ' attached to ' + attachTo, 'success');
          return;
        } else {
          return resp.text().then((text) => {
            throw new Error(text);
          });
        }
      })
      .catch((error) => {
        console.log(error);
        addAlert(error.message, 'danger');
      });
  };

  const onPidSelect = (val) => {
    setPid({ value: val, valid: val === '' ? 'error' : 'success' });
  };

  function mapProj(json: any): Array<string> {
    return json.projects.map((p) => p.name);
  }

  function mapMtzs(json: any): Array<string> {
    const [_, pid] = attachXDC.split('.');
    return json.materializations
      .map((m) => {
        if (xdctype == 'personal' || m.pid == pid) {
	  // personal can attach to any; shared only to the same project
          return m.rid + '.' + m.eid + '.' + m.pid;
        }
        return 'EMPTY';
      })
      .filter((e) => {
        return e !== 'EMPTY';
      }); // there is likely a better way to do this.
  }

  const onXDCChange = (val) => {
    const re = /^[a-z]([a-z0-9]*[a-z0-9])?$/;
    if (re.test(val) === false) {
      setXdc({ value: val, valid: 'error' });
      setInvalidXDC('The XDC name must start with a lowercase and only contain lowercase alphanumeric characters');
    } else {
      setInvalidXDC('');
      setXdc({ value: val, valid: val === '' ? 'error' : 'success' });
    }
  };

  const crumbs = (
    <PageSection>
      <Breadcrumb>
        <BreadcrumbItem>XDCs</BreadcrumbItem>
      </Breadcrumb>
    </PageSection>
  );

  const header = (
    <PageSection>
      <Split>
        <SplitItem>
          <Title headingLevel="h1" size="lg">
            Experiment Development Containers
          </Title>
        </SplitItem>
        <SplitItem isFilled />
        <SplitItem>
          <Button variant="control" aria-label="New XDC" onClick={toggleModal}>
            New XDC
          </Button>
        </SplitItem>
      </Split>
    </PageSection>
  );

  const notready = xdc.valid !== 'success' || pid.valid !== 'success';

  const newModal = (
    <Modal
      isOpen={isModalOpen}
      onClose={toggleModal}
      variant={ModalVariant.medium}
      aria-label="New Experiment Development Container"
    >
      <React.Fragment>
        <Title headingLevel="h1" size="2xl">
          New Experiment Development Container
        </Title>
        <Form isHorizontal>
          {notready && (
            <FormAlert>
              <Alert variant="danger" title="All fields must be filled" aria-live="polite" isInline />
            </FormAlert>
          )}
          <FormGroup fieldId="project" label="Project" validated={pid.valid}>
            <FetchSelect label="Project" url={conf.api + '/project'} onSelect={onPidSelect} mapItems={mapProj} />
          </FormGroup>
          <FormGroup
            fieldId="xdcname"
            label="Name"
            helperText={<FormHelperText isHidden={xdc.valid !== 'success'} />}
            helperTextInvalid={invalidXDC}
            field-id="name"
            validated={xdc.valid}
          >
            <TextInput type="text" id="name" value={xdc.value} onChange={(e) => onXDCChange(e)} />
          </FormGroup>
          <FormGroup fieldId="xdctype" label="Type">
            <FormSelect value={xdctype} aria-label="Type" onChange={(v) => setXDCType(v)}>
              <FormSelectOption key={0} value="shared" label="shared" />
              <FormSelectOption key={1} value="personal" label="personal" />
            </FormSelect>
          </FormGroup>
          <ActionGroup>
            <Button variant="primary" onClick={submitForm} isDisabled={notready} isAriaDisabled={notready}>
              Submit
            </Button>
          </ActionGroup>
        </Form>
      </React.Fragment>
    </Modal>
  );

  const attachModel = (
    <Modal
      isOpen={attachIsOpen}
      onClose={() => setAttachIsOpen(!attachIsOpen)}
      variant={ModalVariant.medium}
      aria-label="Attach to Materialization"
    >
      <React.Fragment>
        <Title headingLevel="h1" size="2xl">
          Attach to Materialization
        </Title>
        <Form isHorizontal>
          <FormGroup fieldId="xdcname" label="XDC" isRequired={true}>
            <TextInput isRequired type="text" id="name" value={attachXDC} isDisabled={true} />
          </FormGroup>
          <FormGroup fieldId="mtz" label="Materialization" isRequired={true}>
            <FetchSelect
              label="Materialization"
              url={conf.api + '/materialize/materializations'}
              onSelect={setAttachTo}
              mapItems={mapMtzs}
            />
          </FormGroup>
          <ActionGroup>
            <Button variant="primary" onClick={submitAttach}>
              Attach
            </Button>
          </ActionGroup>
        </Form>
      </React.Fragment>
    </Modal>
  );

  const notifications = (
    <AlertGroup isToast>
      {alerts.map((a, i) => (
        <Alert variant={AlertVariant[a.var]} title={a.title} timeout={9000} key={i} />
      ))}
    </AlertGroup>
  );

  if (filter) {
    return (
      <React.Fragment>
        {alerts.length !== 0 && notifications}
        {attachModel}
        <ActionList
          kind="XDCs"
          columns={fcolumns}
          url={conf.api + '/xdc/instance/' + fxid + '/' + fpid}
          actions={actions}
          mapper={fmapper}
          reload={reload}
          toolbarDisabled={true}
        />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {alerts.length !== 0 && notifications}
      {crumbs}
      {newModal}
      {attachModel}
      {header}
      <PageSection>
        <ActionList
          kind="XDCs"
          columns={columns}
          url={conf.api + '/xdc/list/'}
          actions={actions}
          mapper={mapper}
          reload={reload}
        />
      </PageSection>
    </React.Fragment>
  );
};

export { XDCs };

import * as types from '@app/Revision/model_types';
import { TopoModel } from '@app/Revision/model_types';
import { Materialization } from '@mergetb/api/portal/v1/materialize_types';

// take a xir model (in json) and convert it into
// something that can be fed to a d3 forge graph.
export const xirmodel2topomodel = (model: types.XirModel, view: types.TopoView): types.TopoModel => {
  const { nodes, links } = model;

  switch (view) {
    case 'lannode':
      return nodelanview(nodes, links);
    case 'links':
      return linksview(nodes, links);
    case 'node':
    default:
      return nodeview(nodes, links);
  }
};

export const mtz2topomodel = (mtz: Materialization): types.TopoModel => {
  const { metal, vms, links } = mtz;
  const res: TopoModel = { nodes: [], links: [] };

  if (metal) {
    for (let i = 0; i < metal.length; i++) {
      res.nodes.push({
        id: metal[i].model?.id,
        type: types.NodeType.Node,
        label: metal[i].model?.id,
      });
    }
  }

  if (vms) {
    for (let i = 0; i < vms.length; i++) {
      res.nodes.push({
        id: vms[i].vmAlloc?.node,
        type: types.NodeType.Node,
        label: vms[i].vmAlloc?.node,
      });
    }
  }

  // breakout all endpoints into point to point links.
  for (let li = 0; li < mtz.links.length; li++) {
    if (mtz.links[li]?.realization?.link?.endpoints !== undefined) {
      for (let i = 0; i < mtz.links[li].realization.link.endpoints.length; i++) {
        for (let j = 0; j < mtz.links[li]!.realization!.link!.endpoints.length; j++) {
          if (i == j) {
            continue;
          }

          if (
            mtz.links[li]?.realization?.link?.endpoints[i] === undefined ||
            mtz.links[li]?.realization?.link?.endpoints[j] === undefined
          ) {
            continue;
          }

          const n1 = mtz.links[li].realization.link.endpoints[i].socket?.element;
          const n2 = mtz.links[li].realization.link.endpoints[j].socket?.element;

          res.links.push({
            id: n1 + '~' + n2,
            label: n1 + '~' + n2,
            source: n1!,
            target: n2!,
          });
        }
      }
    }
  }

  return res;
};

// TODO make all of these functions strongly typed.

//
// Take the xir nodes and links and just map them unto nodes and links.
// Breakout all multilinks (LANs) into point to point links.
//
const nodeview = (nodes, links) => {
  const res = { nodes: [], links: [] };

  if (nodes) {
    for (let i = 0; i < nodes.length; i++) {
      res.nodes.push({
        id: nodes[i].id,
        type: 'node',
        label: nodes[i].id,
        xir: nodes[i],
      });
    }
  }

  // breakout all endpoints into point to point links.
  if (links) {
    for (let li = 0; li < links.length; li++) {
      for (let i = 0; i < links[li].endpoints.length; i++) {
        for (let j = 0; j < links[li].endpoints.length; j++) {
          if (i == j) {
            continue;
          }

          let n1 = links[li].endpoints[i].socket.element;
          let n2 = links[li].endpoints[j].socket.element;

          res.links.push({
            id: n1 + '~' + n2,
            source: n1,
            target: n2,
            xir: links[li],
          });
        }
      }
    }
  }

  return res;
};

//
// Convert xir nodes and links into a node w/LANs view. For all multilinks,
// add an extra "node" that represents the multilink and have links
// connect to that instead of breaking out the links into point to point.
//
const nodelanview = (nodes, links) => {
  const res = { nodes: [], links: [] };

  // build d3 nodes from xir nodes
  if (nodes) {
    for (let i = 0; i < nodes.length; i++) {
      res.nodes.push({
        id: nodes[i].id,
        type: 'node',
        label: nodes[i].id,
        xir: nodes[i],
      });
    }
  }

  // build d3 nodes from mutlilink LANs
  if (links) {
    for (let i = 0; i < links.length; i++) {
      if (links[i].endpoints.length > 2) {
        // multi link
        res.nodes.push({
          id: links[i].id,
          type: 'LAN',
          label: 'LAN',
          xir: links[i],
        });
      }
    }

    // build d3 links from xir links
    for (let l = 0; l < links.length; l++) {
      for (let i = 0; i < links[l].endpoints.length; i++) {
        for (let j = 0; j < links[l].endpoints.length; j++) {
          if (i == j) {
            continue;
          }

          const n1 = links[l].endpoints[i].socket.element;
          let n2 = '';

          if (links[l].endpoints.length == 2) {
            // point to node
            n2 = links[l].endpoints[j].socket.element;
          } else {
            // point to lan,
            n2 = links[l].id;
          }

          res.links.push({
            id: n1 + '~' + n2,
            source: n1,
            target: n2,
            xir: links[l],
          });
        }
      }
    }
  }

  return res;
};

//
// Two types of node in this view: nodes which are the experiment machines and nodes
// per interface on each experiment machine. THis allows people to see interface
// information.
//
const linksview = (nodes, links) => {
  let res = { nodes: [], links: [] };

  // build d3 nodes from xir nodes
  if (nodes) {
    for (let i = 0; i < nodes.length; i++) {
      res.nodes.push({
        id: nodes[i].id,
        type: 'node',
        label: nodes[i].id,
        xir: nodes[i],
      });

      if (nodes[i].sockets) {
        for (let j = 0; j < nodes[i].sockets.length; j++) {
          let lab = nodes[i].id + '.' + nodes[i].sockets[j].index;
          if (nodes[i].sockets[j].addrs && nodes[i].sockets[j].addrs.length != 0) {
            lab = nodes[i].sockets[j].addrs.join('/');
          }

          const socketid = nodes[i].id + '.' + nodes[i].sockets[j].index;

          res.nodes.push({
            id: socketid,
            type: 'socket',
            label: lab,
            xir: nodes[i].sockets[j],
          });

          // link the node to the sockets.
          res.links.push({
            id: nodes[i].id + '~' + socketid,
            source: nodes[i].id,
            target: socketid,
            xir: nodes[i].sockets[j],
          });
        }
      }
    }
  }

  // now build links.
  if (links) {
    for (let li = 0; li < links.length; li++) {
      for (let i = 0; i < links[li].endpoints.length; i++) {
        for (let j = 0; j < links[li].endpoints.length; j++) {
          if (i == j) {
            continue;
          }

          // link the endpoints together
          const sid = links[li].endpoints[i].socket.element + '.' + links[li].endpoints[i].socket.index;
          const tid = links[li].endpoints[j].socket.element + '.' + links[li].endpoints[j].socket.index;

          res.links.push({
            id: sid + '~' + tid,
            source: sid,
            target: tid,
            xir: links[li],
          });
        }
      }
    }
  }

  return res;
};

import * as React from 'react';
import { GetResourcesResponse } from '@mergetb/api/portal/v1/realize_types';
import {
  Card,
  CardTitle,
  CardBody,
  Split,
  SplitItem,
  PageSection,
  CardFooter,
  Text,
  TextVariants,
  Bullseye,
} from '@patternfly/react-core';
import { ChartPie, ChartThemeColor } from '@patternfly/react-charts';

type TopResourcesProps = {
  count: number;
  resources: GetResourcesResponse | undefined;
};

const TopResources: React.FC<TopResourcesProps> = ({ count, resources }) => {
  const height = 200;
  const width = 280;

  if (count == 0) {
    count = 5;
  }

  type piedata = {
    // x, y fields required by chart component.
    x: string;
    y: number;
  };

  type alldata = {
    cores: piedata[];
    mem: piedata[];
    unusedCores: number;
    unusedMem: number;
    totalCores: number;
    totalMem: number;
  };

  const data = React.useMemo(() => {
    if (resources) {
      const cores = new Map<string, number>();
      const mem = new Map<string, number>();
      let memUsed = 0;
      let coresUsed = 0;
      const data: alldata = {
        cores: [],
        mem: [],
        totalCores: 0,
        totalMem: 0,
        unusedCores: 0,
        unusedMem: 0,
      };

      resources.resources.forEach((r) => {
        for (const p of r.resource?.procs || []) {
          data.totalCores += p.cores;
        }
        for (const p of r.resource?.memory || []) {
          data.totalMem += p.capacity;
        }

        // add up data per project.
        r.allocated.forEach((a) => {
          // append current value to total.
          cores.set(a.pid, (cores.get(a.pid) || 0) + a.coresUsed);
          mem.set(a.pid, (mem.get(a.pid) || 0) + a.memoryUsed);
        });
      });

      cores.forEach((value: number, key: string) => {
        data.cores.push({ x: key, y: value });
        coresUsed += value;
      });
      mem.forEach((value: number, key: string) => {
        data.mem.push({ x: key, y: value });
        memUsed += value;
      });

      // sort by usage.
      data.cores.sort((a, b) => (a.y > b.y ? -1 : 1));
      data.mem.sort((a, b) => (a.y > b.y ? -1 : 1));

      data.unusedCores = data.totalCores - coresUsed;
      data.unusedMem = data.totalMem - memUsed;

      // unused at the front
      if (data.unusedCores) {
        data.cores.unshift({ x: 'Unused', y: data.unusedCores });
      }
      if (data.unusedMem) {
        data.mem.unshift({ x: 'Unused', y: data.unusedMem });
      }

      return data;
    }
    return undefined;
  }, [resources]);

  const memLeg = (d) => {
    const c = data?.unusedMem ? count + 1 : count;
    return d.slice(0, c).map((d) => {
      return { name: d.x + ': ' + formatBytes(d.y) };
    });
  };

  const coreLeg = (d) => {
    const c = data?.unusedCores ? count + 1 : count;
    return d.slice(0, c).map((d) => {
      return { name: d.x + ': ' + d.y };
    });
  };

  return (
    <Card>
      <CardTitle>Resource Usage by Project</CardTitle>
      <CardBody>
        <Split>
          {data && data.mem && (
            <SplitItem>
              Of {formatBytes(data.totalMem)} of Memory
              <ChartPie
                ariaDesc="Memory Usage"
                ariaTitle="Memory Usage"
                constrainToVisibleArea={true}
                data={data.mem}
                height={height}
                width={width}
                labels={({ datum }) => datum.x + ': ' + formatBytes(datum.y)}
                legendData={memLeg(data.mem)}
                legendOrientation="vertical"
                legendPosition="right"
                legendAllowWrap={true}
                themeColor={ChartThemeColor.multi}
                padding={{
                  bottom: 40,
                  left: 20,
                  right: 140, // Adjusted to accommodate leged
                  top: 20,
                }}
              />
            </SplitItem>
          )}
          <SplitItem isFilled />
          {data && data.cores && (
            <SplitItem>
              Of {data.totalCores} Machine Cores
              <ChartPie
                ariaDesc="Cores in Use"
                ariaTitle="Cores in Use"
                constrainToVisibleArea={true}
                data={data.cores}
                height={height}
                width={width}
                labels={({ datum }) => (datum.x ? `${datum.x}: ${datum.y}` : null)}
                legendData={coreLeg(data.cores)}
                legendOrientation="vertical"
                legendPosition="right"
                legendAllowWrap={true}
                themeColor={ChartThemeColor.multi}
                padding={{
                  bottom: 40,
                  left: 20,
                  right: 140, // Adjusted to accommodate leged
                  top: 20,
                }}
              />
            </SplitItem>
          )}
        </Split>
      </CardBody>
      <CardFooter>
        <Bullseye>
          <Text component={TextVariants.small}>Only top {count} (and unused) shown in legend.</Text>
        </Bullseye>
      </CardFooter>
    </Card>
  );
};

function formatBytes(bytes, decimals = 0): string {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export { TopResources };

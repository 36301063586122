import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { AuthContext } from '@app/lib/AuthProvider';
import { News } from '@app/News/News';
import { ActiveExperiments } from '@app/Dashboard/ActiveExperiments';
import { InactiveExperiments } from '@app/Dashboard/InactiveExperiments';
import { UserSummary } from '@app/Dashboard/UserSummary';
import { TopResources } from '@app/Dashboard/TopResources';
import {
  Grid,
  GridItem,
  Spinner,
  AlertProps,
  AlertGroup,
  Alert,
  AlertVariant,
  AlertActionCloseButton,
  PageSection,
} from '@patternfly/react-core';
import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import { useFetch } from 'use-http';
import { GetMaterializationsResponse, Materialization, Ingresses } from '@mergetb/api/portal/v1/materialize_types';
import { GetRealizationsResponse, Realization } from '@mergetb/api/portal/v1/realize_types';
import { GetResourcesResponse } from '@mergetb/api/portal/v1/realize_types';
import { GetUserRequest, GetUserResponse } from '@mergetb/api/portal/v1/workspace_types';
import { Port } from '@mergetb/api/mergetb/xir/v0.3/core';

const Dashboard: React.FunctionComponent = () => {
  const [alerts, setAlerts] = React.useState<Partial<AlertProps>[]>([]);
  const { isAuthenticated, session, identity } = React.useContext(AuthContext);
  const [reload, setReload] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!isAuthenticated || !session) {
      return <Redirect to="/login" />;
    }
  }, [isAuthenticated, session]);

  const { api } = React.useContext(GeneralSettingsContext);
  const options = { credentials: 'include', cachePolicy: 'no-cache' };
  const { data: mzdata } = useFetch(api + '/materialize/materializations', options, [reload]);
  const { data: rzdata } = useFetch(api + '/realize/realizations', options, [reload]);
  const { data: resdata } = useFetch(api + '/realize/resources', options, [reload]);
  const { data: userdata } = useFetch(api + '/user/' + identity?.traits?.username, options, [reload]);

  const mtzs = React.useMemo(() => {
    if (mzdata) {
      if (Object.prototype.hasOwnProperty.call(mzdata, 'materializations')) {
        return GetMaterializationsResponse.fromJSON(mzdata);
      }
    }
    return undefined;
  }, [mzdata]);

  const rlzs = React.useMemo(() => {
    if (rzdata) {
      if (Object.prototype.hasOwnProperty.call(rzdata, 'results')) {
        return GetRealizationsResponse.fromJSON(rzdata);
      }
    }
    return undefined;
  }, [rzdata]);

  const resources = React.useMemo(() => {
    if (resdata && Object.prototype.hasOwnProperty.call(resdata, 'resources')) {
      return GetResourcesResponse.fromJSON(resdata);
    }
    return undefined;
  }, [resdata]);

  const user = React.useMemo(() => {
    if (userdata && Object.prototype.hasOwnProperty.call(userdata, 'user')) {
      return GetUserResponse.fromJSON(userdata);
    }
    return undefined;
  }, [userdata]);

  if (!isAuthenticated || !session) {
    return <Redirect to="/login" />;
  }

  const addAlert = (title: string, variant: AlertProps['variant']): void => {
    const key = new Date().getTime();
    setAlerts((prevAlerts) => [...prevAlerts, { title, variant, key }]);
  };

  const removeAlert = (key: React.Key) => {
    setAlerts((prevAlerts) => [...prevAlerts.filter((alert) => alert.key !== key)]);
  };

  const doReload = () => setReload(!reload);

  return (
    <PageSection>
      <AlertGroup isToast isLiveRegion>
        {alerts.map(({ key, variant, title }) => (
          <Alert
            variant={AlertVariant[variant]}
            title={title}
            actionClose={
              <AlertActionCloseButton
                title={title as string}
                variantLabel={`${variant} alert`}
                onClose={() => removeAlert(key)}
              />
            }
            key={key}
          />
        ))}
      </AlertGroup>
      <Grid hasGutter>
        <GridItem span={3} rowSpan={3}>
          <News />
        </GridItem>
        <GridItem span={9}>
          <ActiveExperiments mtzs={mtzs} addAlert={addAlert} reload={doReload} />
        </GridItem>
        <GridItem span={4}>
          <InactiveExperiments mtzs={mtzs} rlzs={rlzs} addAlert={addAlert} reload={doReload} />
        </GridItem>
        <GridItem span={5}>
          <TopResources count={5} resources={resources} />
        </GridItem>
        <GridItem span={9}>
          <UserSummary user={user?.user} reload={doReload} />
        </GridItem>
      </Grid>
    </PageSection>
  );
};

export { Dashboard };

import * as React from 'react';
import { PageSection, Spinner, Bullseye, Alert } from '@patternfly/react-core';
import { NewsList } from '@app/lib/NewsList';
import { env } from '@app/env.js';
import { useFetch } from 'usehooks-ts'; // we use this one instead of the use-http one because CORS wasn't letting us grab it

const News: React.FunctionComponent = () => {
  const url = env.REACT_APP_MERGE_NEWS_URI;

  const { data, error } = useFetch(url);

  return (
    <>
      {error && !data && (
        <Alert variant="danger" title="Error">
          Error loading
        </Alert>
      )}
      {error && data && Object.prototype.hasOwnProperty.call(data, 'message') && (
        <Alert variant="danger" title="Response Error">
          <pre>{JSON.stringify(data, null, 2)}</pre>
        </Alert>
      )}
      {!data && (
        <Bullseye>
          <Spinner size="sm" />
        </Bullseye>
      )}
      {data && Object.prototype.hasOwnProperty.call(data, 'news') && <NewsList data={data} />}
    </>
  );
};

export { News };

import * as React from 'react';
import {
  PageSection,
  Title,
  Breadcrumb,
  BreadcrumbItem,
  Alert,
  AlertGroup,
  AlertActionCloseButton,
  Split,
  SplitItem,
  Popover,
  Button,
  Modal,
  ModalVariant,
  Form,
  FormGroup,
  InputGroup,
  TextInput,
  ActionGroup,
  DatePicker,
  TimePicker,
  isValidDate,
  yyyyMMddFormat,
} from '@patternfly/react-core';
import { Link } from 'react-router-dom';
import { ActionList } from '@app/lib/ActionList';
import { sortable, headerCol } from '@patternfly/react-table';
import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import { AuthContext } from '@app/lib/AuthProvider';
import { UpdateRealizationRequest } from '@mergetb/api/portal/v1/realize_types';
import { useTranslation } from 'react-i18next';
import { toTitleCase } from '@app/lib/util';

const Realizations: React.FunctionComponent = () => {
  var userview_last = (localStorage.getItem('userview') ?? true) == true;

  const conf = React.useContext(GeneralSettingsContext);
  const [reload, setReload] = React.useState(0);
  const [alerts, setAlerts] = React.useState([]);
  const { identity } = React.useContext(AuthContext);
  const username = identity?.traits.username;

  const [viewLabel, setViewLabel] = React.useState('View ' + (userview_last ? 'All' : 'Own'));
  const [userView, setUserView] = React.useState(userview_last);

  const [showRealUpdate, setShowRealUpdate] = React.useState<boolean>(false);
  const [chosenRealName, setChosenRealName] = React.useState<string>('');
  const [expDate, setExpDate] = React.useState<Date>();

  const { t } = useTranslation();

  const columns = [
    { title: toTitleCase(t('realization')), cellTransforms: [headerCol()], transforms: [sortable] },
    { title: 'Experiment', transforms: [sortable] },
    { title: 'Project', cellTransforms: [headerCol()], transforms: [sortable] },
    { title: 'Creator', cellTransforms: [headerCol()], transforms: [sortable] },
    { title: 'Created', cellTransforms: [headerCol()], transforms: [sortable] },
    { title: 'Expires', cellTransforms: [headerCol()], transforms: [sortable] },
    { title: 'Revision' },
    { title: 'Succeeded' },
    { title: 'Nodes' },
    { title: 'Links' },
  ];

  const mapper = (json) =>
    json.results.map((x) => {
      let r = x.realization;
      let num_nodes = 0;
      let num_links = 0;

      if (x.realization) {
        num_nodes = Object.keys(x.realization.nodes).length;
        num_links = Object.keys(x.realization.links).length;
      }

      if (x.summary) {
        r = x.summary;

        num_nodes = r.numNodes;
        num_links = r.numLinks;
      }

      let success_field: React.ReactFragment = 'Yes';
      if (Object.keys(x.diagnostics.value).length !== 0) {
        let highestLevel = 'info';
        x.diagnostics.value.forEach((d) => {
          switch (d.level.toLowerCase()) {
            case 'error':
              highestLevel = 'error';
              break;
            case 'warning':
              if (highestLevel !== 'error') {
                highestLevel = 'warning';
              }
              break;
          }
        });

        let variant;
        let btext;
        switch (highestLevel) {
          case 'error':
            variant = 'danger';
            btext = 'Failed';
            break;
          case 'warning':
            variant = 'warning';
            btext = 'Warning';
            break;
          default:
            variant = 'plain';
            btext = 'Info';
            break;
        }

        success_field = (
          <React.Fragment>
            <Popover
              aria-label={toTitleCase(t('realization')) + 'Diagnostics'}
              headerContent={<div>{toTitleCase(t('realization'))} Diagnostics</div>}
              bodyContent={JSON.stringify(x.diagnostics.value)}
            >
              <Button variant={variant}>{btext}</Button>
            </Popover>
          </React.Fragment>
        );
      }

      return [
        // realization
        {
          title: <Link to={'/realizations/' + r.pid + '/' + r.eid + '/' + r.id}>{r.id}</Link>,
          props: {
            component: 'th',
            text: r.id,
          },
        },

        // experiment
        {
          title: <Link to={'/project/' + r.pid + '/experiment/' + r.eid}>{r.eid}</Link>,
          props: {
            component: 'th',
            text: r.eid,
          },
        },

        // project
        {
          title: <Link to={'/project/' + r.pid}>{r.pid}</Link>,
          props: {
            component: 'th',
            text: r.pid,
          },
        },

        // creator
        {
          title: <Link to={'/user/' + r.creator}>{r.creator}</Link>,
          props: {
            component: 'th',
            text: r.creator,
          },
        },

        // created timestamp
        r.created !== null ? new Date(r.created).toLocaleString() : '',

        // expires timestamp
        r.expires === '1970-01-01T00:00:00Z' || r.expires === null ? 'Never' : new Date(r.expires).toLocaleString(),

        // revision
        {
          title: <Link to={'/model/' + r.pid + '/' + r.eid + '/' + r.xhash}>{r.xhash.substring(0, 8)}</Link>,
          props: {
            component: 'th',
            text: r.xhash.substring(0, 8),
          },
        },

        // succeeded/rlz diagnostics
        success_field,

        // nodes
        num_nodes,

        // links
        num_links,
      ];
    });

  let actions = [
    {
      title: toTitleCase(t('relinquish')),
      onClick: (event, rowId, rowData, extra) => {
        const rid = rowData[0].props.text;
        const eid = rowData[1].props.text;
        const pid = rowData[2].props.text;

        fetch(conf.api + '/realize/realizations/' + pid + '/' + eid + '/' + rid, {
          method: 'DELETE',
          credentials: 'include',
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              return (
                response.text(),
                then((text) => {
                  throw new Error(text);
                })
              );
            }
          })
          .then((json) => {
            addAlert(
              toTitleCase(t('realization')) +
                ' ' +
                rid +
                '.' +
                eid +
                '.' +
                pid +
                ' ' +
                toTitleCase(t('relinquished')) +
                ' .',
              '',
              'success'
            );
            setReload(reload + 1);
          })
          .catch((error) => {
            addAlert('Relinquish Error', error.message, 'danger');
          });
      },
    },
    {
      title: toTitleCase(t('materialize')),
      onClick: (event, rowId, rowData) => {
        const rid = rowData[0].props.text;
        const eid = rowData[1].props.text;
        const pid = rowData[2].props.text;

        fetch(conf.api + '/materialize/materialize/' + pid + '/' + eid + '/' + rid, {
          method: 'PUT',
          credentials: 'include',
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              return response.text().then((text) => {
                throw new Error(text);
              });
            }
          })
          .then((json) => {
            addAlert(
              toTitleCase(t('materialization')) + ' ' + rid + '.' + eid + '.' + pid + ' started.',
              '',
              'success'
            );
            setReload(reload + 1);
          })
          .catch((error) => {
            console.log('got error:', error);
            addAlert(toTitleCase(t('materialize')) + ' Error', error.message, 'danger');
          });
      },
    },
    {
      title: 'Update',
      onClick: (event, rowId, rowData) => {
        const rid = rowData[0].props.text;
        const eid = rowData[1].props.text;
        const pid = rowData[2].props.text;

        setChosenRealName(rid + '.' + eid + '.' + pid);
        setShowRealUpdate(true);
      },
    },
  ];

  const toggleView = () => {
    setViewLabel('View ' + (userView === false ? 'All' : 'Own'));
    localStorage.setItem('userview', userView ? '0' : '1');
    setUserView(!userView);
    setReload(!reload);
  };

  const crumbs = (
    <PageSection>
      <Breadcrumb>
        <BreadcrumbItem>User</BreadcrumbItem>
        <BreadcrumbItem to={'/users/' + username}>{username}</BreadcrumbItem>
        <BreadcrumbItem>{toTitleCase(t('realizations'))}</BreadcrumbItem>
      </Breadcrumb>
    </PageSection>
  );

  const header = (
    <PageSection>
      <Split>
        <SplitItem>
          <Title headingLevel="h1" size="lg">
            {toTitleCase(t('realizations'))}
          </Title>
        </SplitItem>
        <SplitItem isFilled />
        <SplitItem>
          <Button variant="control" aria-label={viewLabel} onClick={toggleView}>
            {viewLabel}
          </Button>
        </SplitItem>
      </Split>
    </PageSection>
  );

  const addAlert = (t, m, v) => {
    setAlerts((prev) => [...prev, { title: t, message: m, variant: v, key: new Date().getTime() }]);
  };

  const removeAlert = (key) => {
    setAlerts([...alerts.filter((el) => el.key !== key)]);
  };

  const notifications = (
    <AlertGroup isToast>
      {alerts.map((a, i) => (
        <Alert
          isExpandable={a.message !== ''}
          variant={a.variant}
          title={a.title}
          key={a.key}
          timeout={8000}
          actionClose={
            <AlertActionCloseButton
              title={a.title}
              variantLabel={`${a.variant} alert`}
              onClose={() => removeAlert(a.key)}
            />
          }
        >
          {a.message}
        </Alert>
      ))}
    </AlertGroup>
  );

  const modalReady = true;

  const submitUpdateReal = () => {
    const start = new Date(Date.now());
    const end = new Date(expDate);
    const minutes = (end.getTime() - start.getTime()) / 1000 / 60;
    const ids = chosenRealName.split('.');
    const req = UpdateRealizationRequest.fromJSON({
      project: ids[2],
      experiment: ids[1],
      realization: ids[0],
      duration: {
        when: 'given',
        duration: minutes + 'm',
      },
    });

    // /realize/realizations/{project}/{experiment}/{realization}
    fetch(conf.api + '/realize/realizations/' + ids[2] + '/' + ids[1] + '/' + ids[0], {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(req),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.text().then((text) => {
            throw new Error(text);
          });
        }
      })
      .catch((error) => {
        console.log('got error:', error);
        addAlert('Update Error', error.message, 'danger');
      });

    setShowRealUpdate(!showRealUpdate);
    setReload(reload + 1);
  };

  const onExpTimeChange = (t, h, m, s, v) => {
    if (isValidDate(expDate)) {
      const updatedDate = new Date(expDate);
      updatedDate.setHours(h);
      updatedDate.setMinutes(m);
      setExpDate(updatedDate);
    }
  };

  const onExpDateChange = (inputDate, newDate) => {
    if (isValidDate(expDate) && isValidDate(newDate) && inputDate === yyyyMMddFormat(newDate)) {
      newDate.setHours(expDate?.getHours());
      newDate.setMinutes(expDate?.getMinutes());
    }

    if (isValidDate(newDate) && inputDate === yyyyMMddFormat(newDate)) {
      setExpDate(newDate);
    }
  };

  const expDateRange = (d: Date) => {
    const now = new Date(Date.now());
    if (yyyyMMddFormat(d) < yyyyMMddFormat(now)) {
      return 'Date cannot be in the past';
    }

    const next = new Date(Date.now() + 30 * 24 * 60 * 60 * 1000);
    if (yyyyMMddFormat(d) > yyyyMMddFormat(next)) {
      return 'Date cannot be more than 30 days from now';
    }
    return '';
  };

  const updateRealModal = (
    <Modal
      isOpen={showRealUpdate}
      onClose={() => setShowRealUpdate(false)}
      variant={ModalVariant.medium}
      aria-label="Update Realization"
    >
      <>
        <Title headingLevel="h1" size="2xl">
          Update Realization
        </Title>
        <Form>
          <FormGroup label="Name" fieldId="real-name">
            <TextInput type="text" aria-label="Realization Name" isDisabled value={chosenRealName} />
          </FormGroup>
          <FormGroup label="Expiration Date" aria-label="Expiration Date">
            <DatePicker onChange={onExpDateChange} validators={[expDateRange]} />
          </FormGroup>
          <FormGroup label="Expiration Time" aria-label="Expiration Time">
            <TimePicker onChange={onExpTimeChange} />
          </FormGroup>
          <ActionGroup>
            <Button variant="primary" onClick={submitUpdateReal} isDisabled={!modalReady} isAriaDisabled={!modalReady}>
              Submit
            </Button>
          </ActionGroup>
        </Form>
      </>
    </Modal>
  );

  let url = conf.api + '/realize/realizations?summary=true';
  if (userView === false) {
    url += '&filter=ByAll';
  }

  return (
    <React.Fragment>
      {alerts.length !== 0 && notifications}
      {updateRealModal}
      {crumbs}
      {header}
      <PageSection>
        <ActionList
          kind={toTitleCase(t('realizations'))}
          columns={columns}
          url={url}
          actions={actions}
          mapper={mapper}
          reload={reload}
        />
      </PageSection>
    </React.Fragment>
  );
};

export { Realizations };

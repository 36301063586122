import * as React from 'react';
import { useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  PageSection,
  Title,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Alert,
  Bullseye,
  Spinner,
  Breadcrumb,
  BreadcrumbItem,
  Popover,
  Button,
  Tooltip,
  Text,
  GridItem,
  Grid,
} from '@patternfly/react-core';
import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import { useFetch } from 'use-http';
import { ActionList } from '@app/lib/ActionList';
import { sortable, headerCol } from '@patternfly/react-table';
import { GetExperimentResponse } from '@mergetb/api/portal/v1/workspace_types';
import { DetailEntry, DetailsTable } from '@app/lib/DetailsTable';
import { ModelEditor } from '@app/ModelEditor/ModelEditor';
import { TaskStatusTable } from '@app/lib/TaskStatusTable';
import { toTitleCase } from '@app/lib/util';

import { useTranslation } from 'react-i18next';

// exp json example
// {
//   "experiment":  {
//     "name":  "aaa",
//     "project":  "aaa",
//     "models":  {
//       "a4227a99e12fec40038fe2e5b8a3970bc4850a23":  {
//         "compiled":  true,
//         "msg":  "Succeeded",
//         "compileTime":  "2021-08-16T20:49:06.849481137Z"
//       }
//     },
//     "repository":  "https://git.dorvan.mergetb.net/aaa/aaa",
//     "creator":  "glawler",
//     "ver":  "2"
//   }
// }

const Experiment: React.FunctionComponent = () => {
  const { pid, eid } = useParams();
  const conf = useContext(GeneralSettingsContext);

  const options = { credentials: 'include', cachePolicy: 'no-cache' };
  // we request status for experiments, as it includes in progress compiliations
  const st_url = conf.api + '/project/' + pid + '/experiment/' + eid + '?statusMS=-1';
  const { loading, error, data } = useFetch(st_url, options, []);

  const exp = React.useMemo(() => {
    if (data && Object.hasOwnProperty.call(data, 'experiment')) {
      return GetExperimentResponse.fromJSON(data);
    }
    return GetExperimentResponse.fromJSON({});
  }, [data]);

  const crumbs = (
    <PageSection>
      <Breadcrumb>
        <BreadcrumbItem to="/project">Projects</BreadcrumbItem>
        <BreadcrumbItem to={'/project/' + pid}>{pid}</BreadcrumbItem>
        <BreadcrumbItem to="/experiment">Experiments</BreadcrumbItem>
        <BreadcrumbItem>{eid}</BreadcrumbItem>
      </Breadcrumb>
    </PageSection>
  );

  const st_getter = (data) => {
    return data.status;
  };

  const details: Array<DetailEntry> = exp?.experiment
    ? [
        { label: 'Name', value: exp.experiment.name },
        { label: 'Project', value: <Link to={'/project/' + exp.experiment.project}>{exp.experiment.project}</Link> },
        { label: 'Repository', value: exp.experiment.repository },
        { label: 'Description', value: exp.experiment.description },
        { label: 'Creator', value: <Link to={'/user/' + exp.experiment.creator}>{exp.experiment.creator}</Link> },
        { label: 'Maintainers', value: exp.experiment.maintainers.join(', ') },
      ]
    : [];

  const revisions = (
    <React.Fragment>
      {error && !data && (
        <Alert variant="danger" title="Error">
          Error loading
        </Alert>
      )}
      {error && data && Object.hasOwnProperty.call(data, 'message') && (
        <Alert variant="danger" title="Response Error">
          {data.message}
        </Alert>
      )}
      {loading && (
        <Bullseye>
          <Spinner size="sm" />
        </Bullseye>
      )}
      {data && Object.hasOwnProperty.call(data, 'experiment') && (
        <Revisions pid={data.experiment.project} eid={data.experiment.name} models={data.experiment.models} />
      )}
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {crumbs}
      <PageSection>
        <Title headingLevel="h1" size="3xl">
          Experiment: {pid}.{eid}
        </Title>
      </PageSection>
      <PageSection>
        <Grid hasGutter>
          <GridItem>
            <Card id="deetsCard">
              <CardHeader>
                <CardTitle id="deetsCardTitle">Details</CardTitle>
              </CardHeader>
              <CardBody>
                {error && !data && (
                  <Alert variant="danger" title="Error">
                    Error loading
                  </Alert>
                )}
                {error && data && Object.hasOwnProperty.call(data, 'message') && (
                  <Alert variant="danger" title="Response Error">
                    {data.message}
                  </Alert>
                )}
                {loading && (
                  <Bullseye>
                    <Spinner size="sm" />
                  </Bullseye>
                )}
                {exp?.experiment && <DetailsTable label="expdeets" entries={details} />}
              </CardBody>
            </Card>
          </GridItem>
          <GridItem>{revisions}</GridItem>
          <GridItem>
            <Card id="deetsCard">
              <CardHeader>
                <CardTitle id="statusCardTitle">Status</CardTitle>
              </CardHeader>
              <CardBody>
                <TaskStatusTable
                  kind={pid + '-tst'}
                  url={st_url}
                  getter={st_getter}
                  ongoingfrequency={5000}
                  completedfrequency={60000}
                  scalingfrequency={1.0 / 10.0}
                />
              </CardBody>
            </Card>
          </GridItem>
        </Grid>
      </PageSection>
    </React.Fragment>
  );
};

type ExpRevProps = {
  pid: string;
  eid: string;
  models: any;
};

const Revisions: React.FunctionComponent<ExpRevProps> = ({ pid, eid, models }) => {
  const { t } = useTranslation();
  const cols = [
    { title: 'Revision', cellTransforms: [headerCol()], transforms: [sortable] },
    { title: toTitleCase(t('realizations')) },
    { title: 'Compilation' },
    { title: 'Timestamp', cellTransforms: [headerCol()], transforms: [sortable] },
  ];

  const keys = Object.keys(models).sort((a, b) => {
    const d1 = Date.parse(models[a].compileTime);
    const d2 = Date.parse(models[b].compileTime);
    return d2 - d1;
  });

  const rows = keys.map((revision, i) => [
    // name
    models[revision].compiled ? (
      {
        title: (
          <React.Fragment key={i}>
            <Tooltip content={revision}>
              <Link to={'/model/' + pid + '/' + eid + '/' + revision}>{revision.substring(0, 8)}</Link>
            </Tooltip>
          </React.Fragment>
        ),
        props: {
          component: 'th',
          text: revision,
        },
      }
    ) : (
      <React.Fragment key={i}>
        <Tooltip content={revision}>
          <Text>{revision.substring(0, 8)}</Text>
        </Tooltip>
      </React.Fragment>
    ),

    // realizations
    <React.Fragment key={'reals-' + i}>
      {models[revision].realizations.map((rlz, ri) => (
        <React.Fragment key={ri}>
          <Link to={'/realizations/' + pid + '/' + eid + '/' + rlz} key={ri}>
            {rlz}
          </Link>
          <p />
        </React.Fragment>
      ))}
    </React.Fragment>,

    // compiliation message
    models[revision].compiled ? (
      models[revision].msg === 'Succeeded' ? (
        'Succeeded'
      ) : (
        'Failed'
      )
    ) : // it would be better to not hardcode a string comparison...
    models[revision].msg.includes('compiling') ? (
      <React.Fragment>
        <Button variant="warning">{models[revision].msg}</Button>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <Popover
          aria-label="Compilation Error"
          headerContent={<div>Compilation Error Message</div>}
          bodyContent={<div>{models[revision].msg}</div>}
        >
          <Button variant="danger">Compile Error</Button>
        </Popover>
      </React.Fragment>
    ),

    // timestamp
    new Date(models[revision].compileTime).toLocaleString(),
  ]);

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <CardTitle id="expModelsTitle">Models</CardTitle>
        </CardHeader>
        <CardBody>
          <ActionList kind="revisions" columns={cols} rows={rows} />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export { Experiment };

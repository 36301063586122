import * as React from 'react';
import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import { useFetch } from 'use-http';
import {
  PageSection,
  Card,
  CardHeader,
  CardBody,
  Spinner,
  Bullseye,
  Alert,
  Button,
  Split,
  Title,
  SplitItem,
  Tabs,
  Tab,
  TabTitleText,
  List,
  ListItem,
} from '@patternfly/react-core';
import { TableComposable, Thead, Tbody, Tr, Td, Th, Caption } from '@patternfly/react-table';
import { GetResourcesResponse } from '@mergetb/api/portal/v1/realize_types';

const Resources: React.FunctionComponent = () => {
  const resourceview_last = (localStorage.getItem('resourceview') ?? true) == true;
  const [viewLabel, setViewLabel] = React.useState('View ' + (resourceview_last ? 'All' : 'Only Available'));
  const [resourceView, setResourceView] = React.useState(resourceview_last);
  const [reload, setReload] = React.useState(1);
  const [now, setNow] = React.useState(new Date().toLocaleString());

  const { api } = React.useContext(GeneralSettingsContext);

  const options = {
    credentials: 'include',
    // do not use the cache as data may change on the server and we want to see that.
    cachePolicy: 'no-cache',
  };

  const { loading, error, data } = useFetch(api + '/realize/resources', options, [reload]);

  // // reload every ten seconds.
  React.useEffect(() => {
    const interval = setInterval(() => {
      setReload(reload + 1);
      setNow(new Date().toLocaleString());
    }, 10000);

    return () => clearInterval(interval);
  }, [reload]);

  const toggleView = () => {
    setViewLabel('View ' + (resourceView === false ? 'All' : 'Only Available'));
    localStorage.setItem('resourceview', resourceView ? '0' : '1');
    setResourceView(!resourceView);
  };

  const header = (
    <PageSection>
      <Split>
        <SplitItem>
          <Title headingLevel="h1" size="lg">
            Testbed Resources
          </Title>
        </SplitItem>
        <SplitItem isFilled />
        <SplitItem>
          <Button variant="control" aria-label={viewLabel} onClick={toggleView}>
            {viewLabel}
          </Button>
        </SplitItem>
      </Split>
    </PageSection>
  );

  return (
    <React.Fragment>
      {header}
      <PageSection>
        <Card id={'resources-card-id'}>
          <CardHeader id={'resources-cardheader-id'}>Testbed Resources as of {now}</CardHeader>
          <CardBody>
            {error && !data && (
              <Alert variant="danger" title="Error">
                Error loading
              </Alert>
            )}
            {error && data && Object.prototype.hasOwnProperty.call(data, 'message') && (
              <Alert variant="danger" title="Response Error">
                <pre>{JSON.stringify(data, null, 2)}</pre>
              </Alert>
            )}
            {loading && (
              <Bullseye>
                <Spinner size="sm" />
              </Bullseye>
            )}
            {data && Object.prototype.hasOwnProperty.call(data, 'resources') && (
              <ResourceTables res={GetFacilityResourceState(data, resourceView)} view={resourceView} />
            )}
          </CardBody>
        </Card>
      </PageSection>
    </React.Fragment>
  );
};

interface ResRow {
  name: string;
  mode: string;
  expNodes: Array<string>;
  freeCores: string;
  freeMem: string;
}

function NewResRow(): ResRow {
  return {
    name: '',
    mode: '',
    expNodes: new Array<string>(),
    freeCores: '',
    freeMem: '',
  };
}

interface FacResources {
  coresTotal: number;
  coresUsed: number;
  memTotal: number;
  memUsed: number;

  rows: Array<ResRow>;
}

function NewFacRes(): FacResources {
  return {
    coresTotal: 0,
    coresUsed: 0,
    memTotal: 0,
    memUsed: 0,
    rows: new Array<ResRow>(),
  };
}

function GetFacilityResourceState(data: any, onlyAvailable: boolean): Map<string, FacResources> {
  const rs = GetResourcesResponse.fromJSON(data);
  const res: Map<string, FacResources> = new Map();

  // this code structure is taken directly from `mrg show resources` code.
  rs.resources.forEach((r) => {
    let rCores = 0;
    let rMem = 0;

    const fac = r.resource?.facility || '';

    if (res.has(fac) !== true) {
      res.set(fac, NewFacRes());
    }

    const fr = res.get(fac);

    for (const p of r.resource?.procs || []) {
      fr.coresTotal += p.cores;
      rCores += p.cores;
    }
    for (const m of r.resource?.memory || []) {
      fr.memTotal += m.capacity;
      rMem += m.capacity;
    }

    const nodeMap: Map<string, Array<string>> = new Map();
    let cores = 0;
    let mem = 0;
    let metal = false;

    r.allocated.forEach((a) => {
      const mzid = a.rid + '.' + a.eid + '.' + a.pid;

      if (nodeMap.has(mzid) == true) {
        nodeMap.get(mzid)?.push(a.node);
      } else {
        nodeMap.set(mzid, [a.node]);
      }

      cores += a.coresUsed;
      mem += a.memoryUsed;

      fr.coresUsed += a.coresUsed;
      fr.memUsed += a.memoryUsed;

      if (a.virt == false) {
        metal = true;
      }
    });

    const row = NewResRow();

    nodeMap.forEach((nodes, mzid) => {
      row.expNodes.push(mzid + '[' + nodes.join(', ') + ']');
    });

    let avail = false;
    if (r.allocated.length == 0) {
      row.mode = 'Idle';
      avail = true;
    } else if (r.allocated.length >= 2 || !metal) {
      row.mode = 'Hypervisor';
      avail = rCores != cores && rMem != mem;
    } else {
      row.mode = 'Bare Metal';
      avail = false;
    }

    if ((onlyAvailable == true && avail) || onlyAvailable == false) {
      row.name = r.resource?.id || '';
      row.freeCores = String(rCores - cores);
      row.freeMem = formatBytes(rMem - mem);
      fr?.rows.push(row);
    }
  });

  return res;
}

interface ResourceTablesProps {
  res: Map<string, FacResources>;
  view: boolean;
}

const ResourceTables: React.FunctionComponent<ResourceTablesProps> = ({ res, view }) => {
  const [activeKey, setActiveKey] = React.useState(0);

  const tCols = {
    tCores: 'Cores',
    tMem: 'Memory',
    fCores: 'Free Cores',
    fMem: 'Free Memory',
  };

  const cols = {
    resource: 'Resource',
    mode: 'Allocation Mode',
    nodes: 'Experiment Nodes',
    fCores: 'Free Cores',
    fMem: 'Free Memory',
  };

  const handleTabClick = (event, tabIndex) => {
    setActiveKey(tabIndex);
  };

  return (
    <React.Fragment>
      <Tabs activeKey={activeKey} onSelect={handleTabClick} isBox={true} aria-label="Testbed Resources">
        {Array.from(res.keys()).map((fac, i) => {
          return (
            <Tab key={fac + i + 'tab'} eventKey={i} title={<TabTitleText>{fac}</TabTitleText>}>
              <Card id={fac + i + 'totals'}>
                <CardBody>
                  <TableComposable aria-label="resources-table-totals" variant="compact" borders="compact">
                    <Caption>Total Resources</Caption>
                    <Thead>
                      <Tr>
                        <Th>{tCols.tCores}</Th>
                        <Th>{tCols.tMem}</Th>
                        <Th>{tCols.fCores}</Th>
                        <Th>{tCols.fMem}</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr key={fac}>
                        <Td dataLabel={tCols.tCores}>{res.get(fac)?.coresTotal}</Td>
                        <Td dataLabel={tCols.tMem}>{formatBytes(res.get(fac)?.memTotal)}</Td>
                        <Td dataLabel={tCols.fCores}>{res.get(fac)?.coresTotal - res.get(fac)?.coresUsed}</Td>
                        <Td dataLabel={tCols.fMem}>{formatBytes(res.get(fac)?.memTotal - res.get(fac)?.memUsed)}</Td>
                      </Tr>
                    </Tbody>
                  </TableComposable>
                </CardBody>
              </Card>
              <Card id={fac + i + 'res'}>
                <CardBody>
                  <TableComposable aria-label="resources-table-totals" variant="compact" borders="compact">
                    <Caption>{view ? 'Available Resources' : 'All Resources'}</Caption>
                    <Thead>
                      <Tr>
                        <Th>{cols.resource}</Th>
                        <Th>{cols.mode}</Th>
                        <Th>{cols.nodes}</Th>
                        <Th>{cols.fCores}</Th>
                        <Th>{cols.fMem}</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {res.get(fac)?.rows.map((row, i) => {
                        return (
                          <Tr key={row.name}>
                            <Td dataLabel={cols.resource}>{row.name}</Td>
                            <Td dataLabel={cols.mode}>{row.mode}</Td>
                            <Td dataLabel={cols.nodes}>
                              {row.expNodes.length > 0 ? (
                                <List isPlain>
                                  {row.expNodes.map((entry, i) => {
                                    return <ListItem key={i}>{entry}</ListItem>;
                                  })}
                                </List>
                              ) : (
                                ''
                              )}
                            </Td>
                            <Td dataLabel={cols.fCores}>{row.freeCores}</Td>
                            <Td dataLabel={cols.fMem}>{row.freeMem}</Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </TableComposable>
                </CardBody>
              </Card>
            </Tab>
          );
        })}
      </Tabs>
    </React.Fragment>
  );
};

function formatBytes(bytes, decimals = 0) {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export { Resources };

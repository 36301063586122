import * as React from 'react';
import { Card, CardTitle, CardBody, Split, SplitItem, PageSection } from '@patternfly/react-core';
import { User as PortalUser } from '@mergetb/api/portal/v1/workspace_types';
import { UserView } from '@app/User/User';

type UserSummaryProps = {
  user: PortalUser | undefined;
  reload: () => void;
};

const UserSummary: React.FC<UserSummaryProps> = ({ user, reload }) => {
  if (user === undefined) {
    return <></>;
  }

  return (
    <Card>
      <CardTitle>Your Account Summary</CardTitle>
      <CardBody>
        <UserView user={user} reload={reload} />
      </CardBody>
    </Card>
  );
};

export { UserSummary };

import * as React from 'react';
import {
  Text,
  PageSection,
  Title,
  Breadcrumb,
  BreadcrumbItem,
  Alert,
  AlertGroup,
  AlertVariant,
} from '@patternfly/react-core';
import { Link } from 'react-router-dom';
import { ActionList } from '@app/lib/ActionList';
import { sortable, headerCol } from '@patternfly/react-table';
import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import {
  GetUsersResponse,
  userStateToJSON,
  accessModeToJSON,
  ActivateUserRequest,
} from '@mergetb/api/portal/v1/workspace_types';
import { AuthContext } from '@app/lib/AuthProvider';

const Users: React.FunctionComponent = () => {
  const conf = React.useContext(GeneralSettingsContext);
  const { isAdmin } = React.useContext(AuthContext);
  const [reload, setReload] = React.useState(0);

  type alertProps = {
    title: string;
    variant: AlertVariant;
  };
  const [alerts, setAlerts] = React.useState<Array<alertProps>>([]);

  let columns = [
    { title: 'Username', cellTransforms: [headerCol()], transforms: [sortable] },
    { title: 'Full Name', cellTransforms: [headerCol()], transforms: [sortable] },
    // { title: 'Email', transforms: [sortable] },
    { title: 'Institution', transforms: [sortable] },
    { title: 'Category', transforms: [sortable] },
    { title: 'Country', transforms: [sortable] },
    { title: 'US State', transforms: [sortable] },
    { title: 'Account State', transforms: [sortable] },
    { title: 'Access Mode', transforms: [sortable] },
    { title: 'Projects' },
    { title: 'Organizations' },
    { title: 'Experiments' },
    { title: 'UID' },
    { title: 'GID' },
  ];

  const actions = React.useMemo(() => {
    if (!isAdmin) {
      return [];
    }

    const addAlert = (t: string, v: AlertVariant) => {
      setAlerts((prev) => {
        return [...prev, { title: t, variant: v }];
      });
      setReload(reload + 1);
    };

    const userAction = (method: string, url: string, message: string) => {
      fetch(conf.api + url, {
        method: method,
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((resp) => {
          if (!resp.ok) {
            return resp
              .json()
              .catch(() => {
                // could not parse json
                addAlert(resp.statusText, AlertVariant.danger);
              })
              .then((json) => {
                addAlert(json.message, AlertVariant.danger);
              });
          }
          // success
          addAlert(message, AlertVariant.success);
          return;
        })
        .catch((error) => {
          addAlert(error.message, AlertVariant.danger);
        });
    };

    return [
      {
        title: 'Activate',
        onClick: (_event, _rowId, rowData) => {
          const username = rowData.username.props.text;
          userAction('POST', '/user/activate/' + username, 'User ' + username + ' set to active.');
        },
      },
      {
        title: 'Freeze',
        onClick: (_event, _rowId, rowData) => {
          const username = rowData.username.props.text;
          userAction('POST', '/user/freeze/' + username, 'User ' + username + ' frozen.');
        },
      },
      {
        title: 'Delete',
        onClick: (_event, _rowId, rowData) => {
          const username = rowData.username.props.text;
          userAction('DELETE', '/user/' + username, 'User ' + username + ' deleted.');
        },
      },
    ];
  }, [isAdmin, conf.api]);

  const notifications = (
    <AlertGroup isToast>
      {alerts.map((p, i) => (
        <Alert variant={p.variant} title={p.title} timeout={3000} key={i} />
      ))}
    </AlertGroup>
  );

  const mapper = (json) => {
    const u = GetUsersResponse.fromJSON(json);
    return u.users.map((x, i) => [
      {
        title: (
          <Link key={i} to={'/user/' + x.username}>
            {x.username}
            {x.admin ? '*' : ''}
          </Link>
        ),
        props: {
          component: 'th',
          text: x.username,
        },
      },
      x.name,
      x.institution,
      x.category,
      x.country,
      x.usstate,
      userStateToJSON(x.state),
      accessModeToJSON(x.accessMode),
      <>
        {Object.keys(x.projects).map((p, i) => (
          <React.Fragment key={i}>
            <Link to={'/project/' + p}>{p}</Link>
            <br />
          </React.Fragment>
        ))}
      </>,
      <>
        {Object.keys(x.organizations).map((o, i) => (
          <React.Fragment key={i}>
            <Link to={'/organization/' + o}>{o}</Link>
            <br />
          </React.Fragment>
        ))}
      </>,
      <>
        {x.experiments.map((x, i) => (
          <React.Fragment key={i}>
            <Link to={'/project/' + x.split('.')[1] + '/experiment/' + x.split('.')[0]}>{x}</Link>
            <br />
          </React.Fragment>
        ))}
      </>,
      x.uid,
      x.gid,
    ]);
  };

  const crumbs = (
    <PageSection>
      <Breadcrumb>
        <BreadcrumbItem>Users</BreadcrumbItem>
      </Breadcrumb>
    </PageSection>
  );

  const header = (
    <PageSection>
      <Title headingLevel="h1" size="lg">
        Users
      </Title>
    </PageSection>
  );

  return (
    <React.Fragment>
      {alerts.length !== 0 && notifications}
      {crumbs}
      {header}
      <PageSection>
        <ActionList
          reload={reload}
          kind="Users"
          columns={columns}
          url={conf.api + '/users'}
          actions={actions}
          mapper={mapper}
        />
      </PageSection>
    </React.Fragment>
  );
};

export { Users };

import * as React from 'react';
import * as ModelTypes from '@app/Revision/model_types';
import { Form, FormGroup, TextInput } from '@patternfly/react-core';

type GraphItemDetailsProps = {
  details: unknown;
};

const GraphItemDetails: React.FunctionComponent<GraphItemDetailsProps> = ({ details: d }) => {
  // TODO - actually parse the details and display ther correct stuff,

  let data = [];

  switch (d.type) {
    case ModelTypes.NodeType.Node:
      data = [
        { label: 'Name', val: d.label },
        { label: 'Metal', val: d.xir.metal ? d.xir.metal.value : 'false' },
        { label: 'Interfaces', val: d.xir.sockets.length },
      ];
      break;
    case ModelTypes.NodeType.LAN:
      data = [
        { label: 'Id', val: d.id },
        { label: 'Links', val: d.xir.endpoints.length },
      ];
      break;
    case ModelTypes.NodeType.Socket:
      data = [
        { label: 'Name', val: d.id },
        { label: 'Addresses', val: d.xir.addrs.join(', ') },
        { label: 'Node Index', val: d.xir.index },
      ];
      break;
  }

  return (
    <React.Fragment>
      <Form isHorizontal>
        {data.map((e, i) => (
          <FormGroup label={e.label} key={i}>
            <TextInput value={e.val} type="text" isDisabled={true} id={'text-form-{i}'} name={'text-form-name-{i}'} />
          </FormGroup>
        ))}
      </Form>
    </React.Fragment>
  );
};

export { GraphItemDetails };

import * as React from 'react';
import { Ingresses } from '@mergetb/api/portal/v1/materialize_types';
import { TableComposable, Thead, Tr, Th, Tbody, Td } from '@patternfly/react-table';
import { PortIcon } from '@patternfly/react-icons';

type MtxIngressesProps = {
  ings: Ingresses | undefined;
};

export const MtzIngresses: React.FC<MtxIngressesProps> = ({ ings }) => {
  const cols = { col: 'Ingresses' };

  return (
    <>
      {ings && ings.ingresses.length !== 0 && (
        <TableComposable aria-label={cols.col} variant="compact" borders={false}>
          <Thead>
            <Tr>
              <Th>{cols.col}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {ings.ingresses.map((ing, i) => {
              if (ing.protocol == 'http' || ing.protocol == 'https') {
                return (
                  <Tr key={i}>
                    <Td dataLabel={cols.col}>
                      <a href={ing.ingress + '/'}>
                        {ing.hostname}:{ing.hostport}
                      </a>
                    </Td>
                  </Tr>
                );
              }
              return (
                <Tr key={i}>
                  <Td dataLabel={cols.col}>
                    {ing.gateway}:{ing.gatewayport}
                    {PortIcon}
                    {ing.hostname}:{ing.hostport}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </TableComposable>
      )}
    </>
  );
};

import * as React from 'react';
import { env } from '@app/env.js';
import { PageSection, PageSectionVariants, Title, TextContent, TextList, TextListItem } from '@patternfly/react-core';

type GenConfType = {
  api: string;
  grpc: string;
  auth: string;
  launchVer: string;
  mergeVer: string;
};

let Gconf: GenConfType = {
  api: env.REACT_APP_MERGE_API_URI,
  grpc: env.REACT_APP_MERGE_GRPC_ENDPOINT,
  auth: env.REACT_APP_MERGE_AUTH_URI,
  launchVer: env.REACT_APP_MERGE_LAUNCH_VERSION,
  mergeVer: env.REACT_APP_MERGE_VERSION,
};

const GeneralSettingsContext = React.createContext<GenConfType>(Gconf);

const GeneralSettings: React.FunctionComponent = () => {
  const conf = React.useContext(GeneralSettingsContext);

  return (
    <React.Fragment>
      <PageSection variant={PageSectionVariants.light}>
        <Title headingLevel="h1" size="lg">
          General Settings
        </Title>
      </PageSection>
      <PageSection variant={PageSectionVariants.darker}>
        <TextContent>
          <TextList component="dl">
            <TextListItem component="dt">Merge API Endpoint</TextListItem>
            <TextListItem component="dd">{conf.api}</TextListItem>
          </TextList>
        </TextContent>
      </PageSection>
      <PageSection variant={PageSectionVariants.light}></PageSection>
    </React.Fragment>
  );
};

export { GeneralSettings, GeneralSettingsContext, GenConfType, Gconf };

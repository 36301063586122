import * as React from 'react';
import { CodeEditor, Language } from '@patternfly/react-code-editor';
type ModelEditorProps = {
  model: string;
  readonly: boolean;
};

const ModelEditor: React.FunctionComponent<ModelEditorProps> = ({ model, readonly }) => {
  const [code, setCode] = React.useState(model);

  const onEditorInit = (editor, monaco) => {
    editor.layout();
    editor.focus();
    monaco.editor.getModels()[0].updateOptions({ tabSize: 4 });
  };

  return (
    <CodeEditor
      isDarkTheme={true}
      isReadOnly={readonly}
      isLanguageVisible
      code={code}
      onChange={(v) => setCode(v)}
      language={Language.python}
      onEditorDidMount={onEditorInit}
      height="800px"
    />
  );
};

export { ModelEditor };

import * as React from 'react';
import { useContext } from 'react';
import {
  Alert,
  AlertGroup,
  AlertVariant,
  TextArea,
  PageSection,
  Title,
  Modal,
  ModalVariant,
  Form,
  FormGroup,
  FormSelect,
  FormSelectOption,
  FormHelperText,
  FormAlert,
  ActionGroup,
  Button,
  TextInput,
  Split,
  SplitItem,
  Breadcrumb,
  BreadcrumbItem,
} from '@patternfly/react-core';
import { ActionList } from '@app/lib/ActionList';
import { sortable, headerCol } from '@patternfly/react-table';
import { Link } from 'react-router-dom';
import { AuthContext } from '@app/lib/AuthProvider';
import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import { FetchSelect } from '@app/lib/FetchSelect';
import { useFetch } from 'use-http';
import {
  GetOrganizationsResponse,
  CreateProjectRequest,
  Member,
  Member_Role,
  Member_State,
  GetProjectsResponse,
  member_StateToJSON,
  member_RoleToJSON,
  accessModeToJSON,
  GetEntityTypeConfigurationsResponse,
} from '@mergetb/api/portal/v1/workspace_types';

const Projects: React.FunctionComponent = () => {
  const userview_last = (localStorage.getItem('userview') ?? true) == true;

  const { api } = useContext(GeneralSettingsContext);
  const { identity } = React.useContext(AuthContext);
  const username = identity?.traits.username;

  const [viewLabel, setViewLabel] = React.useState('View ' + (userview_last ? 'All' : 'Own'));
  const [userView, setUserView] = React.useState(userview_last);

  const [showNew, setShowNew] = React.useState(false);
  const [newProjName, setNewProjName] = React.useState({ value: '', valid: 'error' });
  const [newProjDesc, setNewProjDesc] = React.useState('');
  const [projOrg, setProjOrg] = React.useState('');
  const [projCat, setProjCat] = React.useState('');
  const [projSubCat, setProjSubCat] = React.useState('');
  const [otherSubCat, setOtherSubCat] = React.useState('');
  const [projSubCats, setProjSubCats] = React.useState(Array<string>);
  const [alerts, setAlerts] = React.useState([]);
  const [invalidName, setInvalidName] = React.useState('');
  const [reload, setReload] = React.useState(1);

  const options = {
    credentials: 'include',
    cachePolicy: 'no-cache',
  };

  const { data: entTypeData } = useFetch(api + '/configurations/entitytype', options, []);

  const columns = [
    { title: 'Name', cellTransforms: [headerCol()], transforms: [sortable] },
    { title: 'Experiments', cellTransforms: [headerCol()], transforms: [sortable] },
    { title: 'Members', cellTransforms: [headerCol()], transforms: [sortable] },
    { title: 'Category', cellTransforms: [headerCol()], transforms: [sortable] },
    { title: 'Subcategory', cellTransforms: [headerCol()], transforms: [sortable] },
    { title: 'AccessMode', cellTransforms: [headerCol()], transforms: [sortable] },
    { title: 'Organization', cellTransforms: [headerCol()], transforms: [sortable] },
  ];

  const entityTypes = React.useMemo(() => {
    if (entTypeData) {
      if (Object.prototype.hasOwnProperty.call(entTypeData, 'Types')) {
        const types = GetEntityTypeConfigurationsResponse.fromJSON(entTypeData).Types;
        // Force first category choice in GUI
        if (types.length > 0) {
          setProjCat(types[0].etype);
          return types;
        }
      }
    }
    return undefined;
  }, [entTypeData]);

  const setMode = (pid, mode) => {
    fetch(api + '/project/' + pid, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        accessMode: {
          value: mode,
        },
      }),
    })
      .then((resp) => {
        if (resp.ok) {
          addAlert('Project ' + pid + ' access mode set to ' + mode, AlertVariant.success);
        }
        if (resp.status !== 200) {
          addAlert('Error: ' + resp.statusText, AlertVariant.danger);
        }
        return;
      })
      .catch((error) => {
        console.log(error);
        addAlert(error.message, AlertVariant.danger);
      });
  };

  const actions = [
    {
      title: 'Delete',
      onClick: (event, rowId, rowData) => {
        const projname = rowData.name.props.text;
        fetch(api + '/project/' + projname, {
          method: 'DELETE',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user: username,
            name: projname,
          }),
        })
          .then((resp) => {
            if (resp.ok) {
              addAlert('Project ' + projname + ' Deleted', AlertVariant.success);
            }
            if (resp.status !== 200) {
              addAlert('Error: ' + resp.statusText, AlertVariant.danger);
            }
          })
          .catch((error) => {
            console.log(error);
            addAlert(error.message, AlertVariant.danger);
          });
      },
    },
    {
      isSeparator: true,
    },
    {
      title: 'Set Access Public',
      onClick: (_event, _rowId, rowData) => {
        const pid = rowData.name.props.text;
        setMode(pid, 'Public');
      },
    },
    {
      title: 'Set Access Protected',
      onClick: (_event, _rowId, rowData) => {
        const pid = rowData.name.props.text;
        setMode(pid, 'Protected');
      },
    },
    {
      title: 'Set Access Private',
      onClick: (_event, _rowId, rowData) => {
        const pid = rowData.name.props.text;
        setMode(pid, 'Private');
      },
    },
  ];

  const mapper = (json) => {
    const ps = GetProjectsResponse.fromJSON(json);
    return ps.projects.map((p) => [
      {
        title: <Link to={'/project/' + p.name}>{p.name}</Link>,
        props: {
          component: 'th',
          text: p.name,
        },
      },
      <>
        {p.experiments.map((e) => (
          <>
            <Link to={'/project/' + p.name + '/experiment/' + e}>{e}</Link>
            <br />
          </>
        ))}
      </>,
      <>
        {Object.keys(p.members).map((m, i) => {
          return (
            <>
              <Link key={i} to={'/user/' + m}>
                {m}
              </Link>
              <br />
            </>
          );
        })}
      </>,
      p.category,
      p.subcategory,
      accessModeToJSON(p.accessMode),
      p.organization !== '' ? p.organization + ' / ' + member_StateToJSON(p.orgMembership.state) : '',
    ]);
  };

  const toggleNewProj = () => {
    setShowNew(!showNew);
  };

  const toggleView = () => {
    setViewLabel('View ' + (userView === false ? 'All' : 'Own'));
    localStorage.setItem('userview', userView ? '0' : '1');
    setUserView(!userView);
    setReload(reload + 1);
  };

  const addAlert = (t: string, v: AlertVariant) => {
    setAlerts((prev) => {
      return [...prev, { title: t, variant: v }];
    });
    setReload(reload + 1);
  };

  const onProjNameChange = (val) => {
    const re = /^[a-z]([a-z0-9]*[a-z0-9])?$/;
    if (re.test(val) === false) {
      setNewProjName({ value: val, valid: 'error' });
      setInvalidName('The project name must start with a lowercase and only contain lowercase alphanumeric characters');
    } else {
      setInvalidName('');
      setNewProjName({ value: val, valid: val === '' ? 'error' : 'success' });
    }
  };

  const submitForm = () => {
    const req = CreateProjectRequest.fromJSON({
      user: username,
      project: {
        name: newProjName.value,
        description: newProjDesc,
        category: projCat,
        subcategory: otherSubCat !== '' ? otherSubCat : projSubCat,
      },
    });
    req.project.members[username] = {
      role: Member_Role.Creator,
      state: Member_State.Active,
    };

    if (projOrg !== 'None') {
      req.project.organization = projOrg;
      req.project.orgMembership = {
        role: Member_Role.Member,
        state: Member_State.Active,
      };
    }

    fetch(api + '/project/' + newProjName.value, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(req),
    })
      .then((resp) => {
        if (resp.ok) {
          addAlert('New Project ' + newProjName.value + ' Created', 'success');
          setNewProjDesc('');
          setNewProjName({ value: '', valid: 'error' });
          setProjOrg('');
          toggleNewProj();
          return;
        } else {
          return resp.text().then((text) => {
            throw new Error(text);
          });
        }
      })
      .catch((error) => {
        console.log(error);
        addAlert(error.message, 'danger');
      });
  };

  function mapOrgs(json: any): Array<string> {
    const resp = GetOrganizationsResponse.fromJSON(json);
    const ret: Array<string> = ['None'];
    // find orgs in which the user is active in
    resp.organizations.forEach((o) => {
      for (const user in o.members) {
        const m: Member = o.members[user];
        if (user == username && m.state == Member_State.Active) {
          ret.push(o.name);
          break;
        }
      }
    });
    return ret;
  }

  const handleProjCatChange = (e) => {
    setProjCat(e);
    if (entityTypes) {
      const found = entityTypes.find((o) => o.etype === e);
      if (found && found.subtypes) {
        setProjSubCats(found.subtypes);
      }
    }
  };

  const notready =
    newProjName.value === '' || newProjDesc === '' || projCat === '' || (projSubCat === 'Other' && otherSubCat === '');

  const newModal = (
    <Modal isOpen={showNew} onClose={toggleNewProj} variant={ModalVariant.medium} aria-label="New Project">
      <React.Fragment>
        <Title headingLevel="h1" size="2xl">
          New Project
        </Title>
        <Form>
          {notready && (
            <FormAlert>
              <Alert variant="danger" title="All fields must be filled" aria-live="polite" isInline />
            </FormAlert>
          )}
          <FormGroup
            label="Name"
            fieldId="name"
            validated={newProjName.valid}
            helperText={<FormHelperText isHidden={newProjName.valid !== 'success'} />}
            helperTextInvalid={invalidName}
          >
            <TextInput type="text" id="name" value={newProjName.value} onChange={(e) => onProjNameChange(e)} />
          </FormGroup>
          <FormGroup label="Description">
            <TextArea type="text" id="name" value={newProjDesc} autoResize={true} onChange={(e) => setNewProjDesc(e)} />
          </FormGroup>
          <FormGroup label="Category">
            <FormSelect value={projCat} onChange={handleProjCatChange} aria-label="Category">
              {entityTypes && entityTypes.map((e, i) => <FormSelectOption key={i} value={e.etype} label={e.etype} />)}
            </FormSelect>
          </FormGroup>
          {projSubCats.length !== 0 && (
            <React.Fragment>
              <FormGroup label="Subcategory">
                <FormSelect value={projSubCat} onChange={(e) => setProjSubCat(e)} aria-label="Subcategory">
                  {projSubCats.map((e, i) => (
                    <FormSelectOption key={i} value={e} label={e} />
                  ))}
                </FormSelect>
              </FormGroup>
            </React.Fragment>
          )}
          {projSubCat === 'Other' && (
            <React.Fragment>
              <FormGroup label="Other Subcategory">
                <TextInput
                  isRequired
                  type="text"
                  id="othersubcat"
                  value={otherSubCat}
                  onChange={(e) => setOtherSubCat(e)}
                />
              </FormGroup>
            </React.Fragment>
          )}
          <FormGroup label="Organization">
            <FetchSelect
              label="Organization"
              url={api + '/organization'}
              onSelect={(v) => setProjOrg(v)}
              mapItems={mapOrgs}
            />
          </FormGroup>
          <ActionGroup>
            <Button variant="primary" onClick={submitForm} isDisabled={notready} isAriaDisabled={notready}>
              Submit
            </Button>
          </ActionGroup>
        </Form>
      </React.Fragment>
    </Modal>
  );

  const crumbs = (
    <PageSection>
      <Breadcrumb>
        <BreadcrumbItem>Projects</BreadcrumbItem>
      </Breadcrumb>
    </PageSection>
  );

  const header = (
    <PageSection>
      <Split>
        <SplitItem>
          <Title headingLevel="h1" size="lg">
            Projects
          </Title>
        </SplitItem>
        <SplitItem isFilled />
        <SplitItem>
          <Button variant="control" aria-label={viewLabel} onClick={toggleView}>
            {viewLabel}
          </Button>
          <Button variant="control" aria-label="New Project" onClick={toggleNewProj}>
            New Project
          </Button>
        </SplitItem>
      </Split>
    </PageSection>
  );

  const notifications = (
    <AlertGroup isToast>
      {alerts.map((a, i) => (
        <Alert variant={AlertVariant[a.var]} title={a.title} timeout={3000} key={i} />
      ))}
    </AlertGroup>
  );

  let url = api + '/project';
  if (userView === false) {
    url += '?filter=ByAll';
  }

  return (
    <React.Fragment>
      {alerts.length !== 0 && notifications}
      {newModal}
      {crumbs}
      {header}
      <PageSection>
        <ActionList kind="Projects" columns={columns} url={url} actions={actions} mapper={mapper} reload={reload} />
      </PageSection>
    </React.Fragment>
  );
};

export { Projects };

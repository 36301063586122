import * as React from 'react';
import {
  Toolbar,
  ToolbarItem,
  ToolbarContent,
  InputGroup,
  Alert,
  Spinner,
  SearchInput,
  EmptyState,
  Bullseye,
  Title,
  EmptyStateIcon,
  EmptyStateBody,
} from '@patternfly/react-core';
import { Table, TableHeader, TableBody, SortByDirection } from '@patternfly/react-table';
import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import { useHistory } from 'react-router-dom';

import CubesIcon from '@patternfly/react-icons/dist/esm/icons/cubes-icon';
import SearchIcon from '@patternfly/react-icons/dist/js/icons/search-icon';

interface ActionListToolbarProps {
  kind: string;
  setSearch: any;
}

const ActionListToolbar: React.FunctionComponent<ActionListToolbarProps> = ({ kind, setSearch }) => {
  const items = (
    <React.Fragment>
      <ToolbarItem variant="search-filter">
        <InputGroup>
          <SearchInput
            name="searchinput"
            id={kind + 'searchinput'}
            type="search"
            aria-label="search input example"
            onChange={(text) => setSearch(text)}
          />
        </InputGroup>
      </ToolbarItem>
    </React.Fragment>
  );

  return (
    <Toolbar id="toolbar">
      <ToolbarContent>{items}</ToolbarContent>
    </Toolbar>
  );
};

interface ActionListProps {
  kind: string;
  url?: string;
  reload: number;
  variant?: string;
  borders?: string;
  columns: any;
  rows?: any;
  actions?: any;
  mapper?: any;
}

const ActionList: React.FunctionComponent<ActionListProps> = (props) => {
  var dataRef = React.useRef([]);
  const [rows, setRows] = React.useState([]);
  const [sortBy, setSortBy] = React.useState({});
  const [idSearch, setIdSearch] = React.useState('');
  const [alert, setAlert] = React.useState('');
  const [atitle, setATitle] = React.useState('');
  const conf = React.useContext(GeneralSettingsContext);
  const { reload, mapper } = props;
  const history = useHistory();
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    if (props.url) {
      fetch(props.url, { credentials: 'include' })
        .then((response) => {
          if (response.status == 401) {
            let err = new Error();
            err.name = 'autherror';
            throw err;
          }
          if (!response.ok) {
            console.log('response not ok: ', response);
            throw response;
          }
          return response.json();
        })
        .then((json) => {
          dataRef.current = props.mapper(json);
          setLoaded(true);
          setRows([...dataRef.current]);
        })
        .catch((error) => {
          if (error.name === 'autherror') {
            const href = conf.auth + '/login?return_to=' + window.location.href;
            window.location.href = href;
          } else if (typeof error.json === 'function') {
            error.json().then((json) => {
              setATitle('Merge API Error');
              json.url = props.url;
              setAlert(json);
            });
          } else {
            console.log('action list error', error);
            setATitle('Network/Fetch Error');
            setAlert(error);
            history.push('/login');
          }
        });
    } else if (props.rows) {
      // Use the rows provided instead of fetching them.
      setLoaded(true);
      dataRef.current = props.rows;
      setRows([...dataRef.current]);
    }
  }, [reload, props.rows]);

  React.useEffect(() => {
    // Search will search by string if the cell is a string or by the
    // props.text field if the cell is an object (and has a props.text field).
    if (idSearch != '') {
      let filtered = dataRef.current.filter((x) => {
        for (let i = 0; i < x.length; i++) {
          if (typeof x[i] === 'string' || x[i] instanceof String) {
            if (x[i].includes(idSearch)) {
              return true;
            }
          } else if (x[i].hasOwnProperty('props') && x[i].props.hasOwnProperty('text')) {
            if (x[i].props.text.includes(idSearch)) {
              return true;
            }
          }
        }
        return false;
      });
      setRows([...filtered]);
    } else {
      setRows(dataRef.current);
    }
  }, [idSearch]);

  const onSort = (_event, index, direction) => {
    const sortedRows = [...rows].sort((a, b) => {
      if (typeof a[index] === 'string') {
        return a[index] < b[index] ? -1 : a[index] > b[index] ? 1 : 0;
      } else if (a[index].hasOwnProperty('props') && a[index].props.hasOwnProperty('text')) {
        const x = a[index].props.text;
        const y = b[index].props.text;
        return x < y ? -1 : x > y ? 1 : 0;
      }
      return 0;
    });
    setSortBy({ index, direction });
    if (direction == SortByDirection.asc) {
      setRows([...sortedRows]);
    } else {
      setRows([...sortedRows.reverse()]);
    }
  };

  const areActionsDisabled = (rowData, { rowIndex }) => {
    return false;
  };

  const variant = props.variant ? props.variant : 'compact';
  const borders = props.borders ? props.borders : 'compactBorderless';

  return (
    <React.Fragment>
      {alert && (
        <Alert variant="danger" title={atitle}>
          <pre>{JSON.stringify(alert, null, 2)}</pre>
        </Alert>
      )}
      {!loaded && (
        <Bullseye>
          <Spinner />
        </Bullseye>
      )}
      {loaded && rows.length !== 0 && (
        <React.Fragment>
          {!props.toolbarDisabled && <ActionListToolbar kind={props.kind} setSearch={setIdSearch} />}
          <Table
            aria-label="Actions table"
            sortBy={sortBy}
            onSort={onSort}
            cells={props.columns}
            rows={rows}
            actions={props.actions}
            areActionsDisabled={areActionsDisabled}
            dropdownPosition="right"
            dropdownDirection="bottom"
            borders={borders}
            variant={variant}
          >
            <TableHeader />
            <TableBody />
          </Table>
        </React.Fragment>
      )}
      {loaded && rows.length == 0 && (
        <>
          <EmptyState>
            <EmptyStateIcon icon={CubesIcon} />
            <Title headingLevel="h4">No {props.kind} found</Title>
            <EmptyStateBody />
          </EmptyState>
        </>
      )}
    </React.Fragment>
  );
};

export { ActionList };

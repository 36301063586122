import * as React from 'react';
import { useContext } from 'react';
import {
  PageSection,
  Title,
  Modal,
  ModalVariant,
  Form,
  FormGroup,
  FormAlert,
  FormHelperText,
  Alert,
  TextInput,
  TextArea,
  Split,
  SplitItem,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Spinner,
} from '@patternfly/react-core';
import { ActionList } from '@app/lib/ActionList';
import { FetchSelect } from '@app/lib/FetchSelect';
import { sortable, headerCol } from '@patternfly/react-table';
import { Link } from 'react-router-dom';
import { AuthContext } from '@app/lib/AuthProvider';
import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';

const Experiments: React.FunctionComponent = () => {
  const { api } = useContext(GeneralSettingsContext);
  const [reload, setReload] = React.useState(0);
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  let columns = [
    { title: 'Name', cellTransforms: [headerCol()], transforms: [sortable] },
    { title: 'Project', cellTransforms: [headerCol()], transforms: [sortable] },
    { title: 'Access Mode', transforms: [sortable] },
    { title: 'Creator', transforms: [sortable] },
  ];

  let actions = [
    // {
    //     title: 'Edit',
    //     onClick: (event, rowId, rowData, extra) => console.log('Editing project: ', rowData.name)
    // },
    // {
    //     isSeparator: true
    // },
    {
      title: 'Delete',
      onClick: (event, rowId, rowData) => {
        const pid = rowData[1].props.text;
        const eid = rowData[0].props.text;

        fetch(api + '/project/' + pid + '/experiment/' + eid, {
          method: 'DELETE',
          credentials: 'include',
        })
          .then((response) => {
            setReload(reload + 1);
          })
          .catch((error) => {
            // TODO take evasive action
          });
      },
    },
  ];

  let mapper = (json) => {
    return json.experiments.map((x) => [
      {
        title: <Link to={'/project/' + x.project + '/experiment/' + x.name}>{x.name}</Link>,
        props: {
          component: 'th',
          text: x.name,
        },
      },
      {
        title: <Link to={'/project/' + x.project}>{x.project}</Link>,
        props: {
          component: 'th',
          text: x.project,
        },
      },
      x.accessMode,
      x.creator,
    ]);
  };

  const header = (
    <PageSection>
      <Split>
        <SplitItem>
          <Title headingLevel="h1" size="lg">
            Experiments
          </Title>
        </SplitItem>
        <SplitItem isFilled />
        <SplitItem>
          <Button variant="control" aria-label="New Experiment" onClick={() => setIsModalOpen(true)}>
            New Experiment
          </Button>
        </SplitItem>
      </Split>
    </PageSection>
  );

  const crumbs = (
    <PageSection>
      <Breadcrumb>
        <BreadcrumbItem>Experiments</BreadcrumbItem>
      </Breadcrumb>
    </PageSection>
  );

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    setReload(reload + 1);
  };

  return (
    <React.Fragment>
      <NewExperiment isOpen={isModalOpen} onClose={toggleModal} />
      {crumbs}
      {header}
      <PageSection>
        <ActionList
          kind="Experiments"
          columns={columns}
          url={api + '/experiment'}
          actions={actions}
          mapper={mapper}
          reload={reload}
        />
      </PageSection>
    </React.Fragment>
  );
};

type NewExperimentProps = {
  isOpen: boolean;
  onClose: () => void;
};

const NewExperiment: React.FunctionComponent<NewExperimentProps> = (props) => {
  const { loading: authLoading, identity } = useContext(AuthContext);
  const { api } = useContext(GeneralSettingsContext);

  const [xp, setXp] = React.useState({ value: '', valid: 'error' });
  const [invalidXp, setInvalidXp] = React.useState('');
  const [pid, setPid] = React.useState({ value: '', valid: 'error' });
  // const [maintainers, setMaintainers] = React.useState([]);
  const [desc, setDesc] = React.useState('');

  const submitForm = () => {
    fetch(api + '/project/' + pid.value + '/experiment/' + xp.value, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        experiment: {
          name: xp.value,
          project: pid.value,
          accessMode: 'Public',
          creator: identity?.traits.username,
          // maintainers: maintainers,
        },
      }),
    })
      .then((resp) => {
        props.onClose();
        setXp({ value: '', valid: 'error' });
        setPid({ value: '', valid: 'error' });
        setDesc('');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onPidSelect = (val) => {
    setPid({ value: val, valid: val === '' ? 'error' : 'success' });
  };

  const onXpChange = (val) => {
    const re = /^[a-z]([a-z0-9]*[a-z0-9])?$/;
    if (re.test(val) === false) {
      setXp({ value: val, valid: 'error' });
      setInvalidXp(
        'The experiment name must start with a lowercase and only contain lowercase alphanumeric characters'
      );
    } else {
      setInvalidXp('');
      setXp({ value: val, valid: val === '' ? 'error' : 'success' });
    }
  };

  // const onMaintainersSelect = (ms) => {
  //     setMaintainers(ms)
  // }

  function mapProj(json: any): Array<string> {
    return json.projects.map((p) => p.name);
  }

  const notready = xp.valid !== 'success' || pid.valid !== 'success' || desc === '';

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} variant={ModalVariant.medium} aria-label="New Experiment">
      <React.Fragment>
        <Title headingLevel="h1" size="2xl">
          New Experiment
        </Title>
        <Form isHorizontal>
          {notready && (
            <FormAlert>
              <Alert variant="danger" title="All fields must be filled" aria-live="polite" isInline />
            </FormAlert>
          )}
          <FormGroup fieldId="project" label="Project" validated={pid.valid}>
            <FetchSelect label="Project" url={api + '/project'} onSelect={onPidSelect} mapItems={mapProj} />
          </FormGroup>
          <FormGroup fieldId="creator" label="Creator">
            {authLoading === false ? (
              <TextInput value={identity?.traits.username} aria-label="Creator" isDisabled />
            ) : (
              <Spinner isSVG size="sm" aria-label="Loading creator" />
            )}
          </FormGroup>
          <FormGroup
            fieldId="name"
            label="Name"
            validated={xp.valid}
            helperText={<FormHelperText isHidden={xp.valid !== 'success'} />}
            helperTextInvalid={invalidXp}
          >
            <TextInput type="text" id="name" value={xp.value} onChange={(e) => onXpChange(e)} />
          </FormGroup>
          <FormGroup fieldId="desc" label="Description">
            <TextArea id="desc" value={desc} autoResize={true} onChange={(e) => setDesc(e)} />
          </FormGroup>
          <FormGroup fieldId="submit">
            <Button variant="primary" onClick={submitForm} isDisabled={notready} isAriaDisabled={notready}>
              {authLoading ? 'Loading User' : 'Submit'}
            </Button>
          </FormGroup>
        </Form>
      </React.Fragment>
    </Modal>
  );
};

export { Experiments };

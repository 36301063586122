import * as React from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  LoginFooterItem,
  LoginMainFooterBandItem,
  LoginPage,
  ListItem,
  ListVariant,
  EmptyState,
  EmptyStateIcon,
  Spinner,
  Level,
  LevelItem,
  Brand,
  Button,
  Stack,
  StackItem,
  Bullseye,
} from '@patternfly/react-core';
import ory from '@app/lib/OrySdk';
import { AuthContext } from '@app/lib/AuthProvider';

import { useTranslation } from 'react-i18next';

import launchLogo from '@app/bgimages/launch-logo.png';
import bgImage1200 from '@app/bgimages/bg-1200.png';
import bgImage768 from '@app/bgimages/bg-768.png';
import bgImage768_2x from '@app/bgimages/bg-768_2x.png';
import bgImage576 from '@app/bgimages/bg-576.png';
import bgImage576_2x from '@app/bgimages/bg-576_2x.png';

import nsfLogo from '@app/bgimages/nsf-logo-60.png';

import { LoginFlow, UpdateLoginFlowBody } from '@ory/client';
import { UserAuthCard } from '@ory/elements';
import { sdkError } from '@app/lib/OrySdk';

const MergeLoginPage: React.FunctionComponent = () => {
  const [flow, setFlow] = React.useState<LoginFlow>();
  const history = useHistory();
  const { setSession, isAuthenticated } = React.useContext(AuthContext);

  const { t } = useTranslation();

  if (isAuthenticated) {
    // already logged in.
    history.push('/');
  }

  const params = React.useMemo(() => {
    return new URLSearchParams(document.location.search);
  }, []);
  const aal2 = params.get('aal2');
  const loginChallenge = params.get('login_challenge');
  const returnTo = params.get('return_to');

  // initialize the sdkError for generic handling of errors
  const sdkErrorHandler = sdkError(undefined, setFlow, '/recovery');

  // Get the flow based on the flowId in the URL (.e.g redirect to this page after flow initialized)
  const getFlow = React.useCallback(
    (flowId: string) =>
      ory
        // the flow data contains the form fields, error messages and csrf token
        .getLoginFlow({ id: flowId })
        .then(({ data: flow }) => setFlow(flow))
        .catch(sdkErrorHandler),
    [sdkErrorHandler]
  );

  // Create a new login flow
  const createFlow = React.useCallback(
    () =>
      ory
        .createBrowserLoginFlow({
          refresh: true,
          aal: aal2 ? 'aal2' : 'aal1',
          ...(loginChallenge && { loginChallenge: loginChallenge }),
          ...(returnTo && { returnTo: returnTo }),
        })
        // flow contains the form fields and csrf token
        .then(({ data: flow }) => {
          // Update URI query params to include flow id
          const params = new URLSearchParams({ ['flow']: flow.id });
          history.replace({ pathname: location.pathname, search: params.toString() });
          // Set the flow data
          setFlow(flow);
        })
        .catch(sdkErrorHandler),
    [sdkErrorHandler, aal2, returnTo, history, loginChallenge]
  );

  // submit the login form data to Ory
  const submitFlow = (body: UpdateLoginFlowBody) => {
    // something unexpected went wrong and the flow was not set
    if (!flow) return history.push('/login', { replace: true });

    // we submit the flow to Ory with the form data
    ory
      .updateLoginFlow({ flow: flow.id, updateLoginFlowBody: body })
      .then(({ data: session }) => {
        setSession(session.session);
        // we successfully submitted the login flow, so lets redirect to the dashboard
        history.push('/', { replace: true });
      })
      .catch(sdkErrorHandler);
  };

  React.useEffect(() => {
    // we might redirect to this page after the flow is initialized, so we check for the flowId in the URL
    const flowId = params.get('flow');
    // the flow already exists
    if (flowId) {
      getFlow(flowId).catch(createFlow); // if for some reason the flow has expired, we need to get a new one
      return;
    }

    // we assume there was no flow, so we create a new one
    createFlow();
  }, [params, getFlow, createFlow]);

  // TODO: Add Terms of Use and Privacy Policy
  const footerListItems = (
    <React.Fragment>
      <Stack hasGutter>
        <StackItem>
          <Level hasGutter>
            <LevelItem>
              <LoginFooterItem href="https://mergetb.gitlab.io/testbeds/sphere/sphere-docs/docs/experimentation/">
                Documentation
              </LoginFooterItem>
            </LevelItem>
            <LevelItem>
              <LoginFooterItem href="https://mergetb.gitlab.io/testbeds/sphere/sphere-docs/docs/experimentation/hello-world-gui/">
                HOWTO
              </LoginFooterItem>
            </LevelItem>
            <LevelItem>
              <LoginFooterItem href="https://chat.mergetb.net/mergetb">Chat</LoginFooterItem>
            </LevelItem>
            <LevelItem>
              <LoginFooterItem href="https://gitlab.com/mergetb">Source Code</LoginFooterItem>
            </LevelItem>
            <LevelItem>
              <LoginFooterItem href="https://sphere-project.net">SPHERE Project</LoginFooterItem>
            </LevelItem>
          </Level>
        </StackItem>
        <StackItem isFilled></StackItem>
        <StackItem>
          <a href="https://nsf.gov" target="_blank" rel="noreferrer">
            <Bullseye>
              <Brand src={nsfLogo} alt="NSF" />
              National Science Foundation
            </Bullseye>
          </a>
        </StackItem>
      </Stack>
    </React.Fragment>
  );

  const images = {
    lg: bgImage1200,
    sm: bgImage768,
    sm2x: bgImage768_2x,
    xs: bgImage576,
    xs2x: bgImage576_2x,
  };

  const signUpForAccountMessage = (
    <LoginMainFooterBandItem>
      <Stack>
        <StackItem>
          Need a new account? <Link to="/registration">Register here.</Link>
        </StackItem>
      </Stack>
    </LoginMainFooterBandItem>
  );

  const forgotCredentials = (
    <LoginMainFooterBandItem>
      <Link to="/recovery">Forgot username or password?</Link>
    </LoginMainFooterBandItem>
  );

  return flow ? (
    <LoginPage
      loginTitle=""
      // loginSubtitle={'With your Merge account'}
      footerListItems={footerListItems}
      footerListVariants={ListVariant.inline}
      backgroundImgAlt="Merge TB"
      brandImgSrc={launchLogo}
      textContent={
        'Welcome to Launch, a portal to the Security and Privacy Heterogeneous Environment for Reproducible Experimentation (SPHERE) testbed clusters. Powered by Merge Testbed technology.'
      }
      backgroundImgSrc={images}
      backgroundImgAlt={'MergeTB'}
      signUpForAccountMessage={signUpForAccountMessage}
      forgotCredentials={forgotCredentials}
    >
      {flow ? (
        <UserAuthCard
          title={t('login_message')}
          flow={flow}
          flowType={'login'}
          includeScripts={false}
          onSubmit={({ body }) => submitFlow(body as UpdateLoginFlowBody)}
        />
      ) : (
        <Spinner />
      )}
    </LoginPage>
  ) : (
    <EmptyState>
      <EmptyStateIcon variant="container" component={Spinner} />
      Contacting Authorization Server
    </EmptyState>
  );
};

export { MergeLoginPage };

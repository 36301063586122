import * as React from 'react';

import { LoginPage, ListVariant } from '@patternfly/react-core';
import { UserInfo } from '@app/UserInfo/UserInfo';

import launchLogo from '@app/bgimages/launch-logo.png';
import bgImage1200 from '@app/bgimages/bg-1200.png';
import bgImage768 from '@app/bgimages/bg-768.png';
import bgImage768_2x from '@app/bgimages/bg-768.png';
import bgImage576 from '@app/bgimages/bg-576.png';
import bgImage576_2x from '@app/bgimages/bg-576.png';

const RegisterUserInfo: React.FunctionComponent = () => {
  const images = {
    lg: bgImage1200,
    sm: bgImage768,
    sm2x: bgImage768_2x,
    xs: bgImage576,
    xs2x: bgImage576_2x,
  };

  return (
    <LoginPage
      loginTitle={'Configure Merge User Account'}
      // footerListItems={footerListItems}
      footerListVariants={ListVariant.inline}
      brandImgSrc={launchLogo}
      brandImgAlt="Merge Launch"
      textContent={
        "Once you have registered, you will need to contact a Merge Portal operator or your organization's Portal adminstrator to initialize and activate your account. Until your account is approved you will be able to login, but have access to no Merge resources."
      }
      backgroundImgSrc={images}
    >
      <UserInfo existingUser={false} />
    </LoginPage>
  );
};

export { RegisterUserInfo };

import React from 'react';
import {
  AboutModal,
  TextContent,
  TextList,
  TextListItem,
  Brand,
  Card,
  CardTitle,
  CardBody,
  CardFooter,
  Level,
  LevelItem,
  Stack,
  StackItem,
} from '@patternfly/react-core';
import logo from '@app/bgimages/launch-logo.png';
import bg from '@app/bgimages/about.png';
import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import nsfLogo from '@app/bgimages/nsf-logo-75.png';
import dhsLogo from '@app/bgimages/dhs-logo-75.png';
import darpaLogo from '@app/bgimages/darpa-logo.png';
import arpaeLogo from '@app/bgimages/arpa-e-logo.png';

interface AboutProps {
  toggle: any;
  isOpen: boolean;
}

export const About: React.FunctionComponent<AboutProps> = ({ toggle, isOpen }) => {
  const conf = React.useContext(GeneralSettingsContext);

  return (
    <React.Fragment>
      <AboutModal
        isOpen={isOpen}
        onClose={toggle}
        trademark="Copyright 2019-2024 Information Sciences Institute"
        brandImageSrc={logo}
        brandImageAlt="MergeTBLogo"
        productName="SPHERE Launch"
        backgroundImageSrc={bg}
      >
        <Stack hasGutter>
          <StackItem>
            <TextContent>
              <TextList component="dl">
                <TextListItem component="dt">Launch Version</TextListItem>
                <TextListItem component="dd">{conf.launchVer}</TextListItem>
                <TextListItem component="dt">Merge Testbed Version</TextListItem>
                <TextListItem component="dd">{conf.mergeVer}</TextListItem>
                <TextListItem component="dt">Merge API Endpoint</TextListItem>
                <TextListItem component="dd">{conf.api}</TextListItem>
                <TextListItem component="dt">Merge GRPC Endpoint</TextListItem>
                <TextListItem component="dd">{conf.grpc}</TextListItem>
              </TextList>
            </TextContent>
          </StackItem>
          <StackItem>
            <Card>
              <CardTitle>Sponsors</CardTitle>
              <CardBody>
                <Level>
                  <LevelItem>
                    <Brand src={nsfLogo} alt="NSF" />
                  </LevelItem>
                  <LevelItem>
                    <Brand src={dhsLogo} alt="DHS" />
                  </LevelItem>
                  <LevelItem>
                    <Brand src={darpaLogo} alt="DARPA" />
                  </LevelItem>
                  <LevelItem>
                    <Brand src={arpaeLogo} alt="ARPA-E" />
                  </LevelItem>
                </Level>
              </CardBody>
              <CardFooter>
                Merge was developed with support from the US government, including DARPA, ARPA-E, DHS, and NSF. This
                material is based upon work supported by the National Science Foundation under Grant No. 2016643.
              </CardFooter>
            </Card>
          </StackItem>
        </Stack>
      </AboutModal>
    </React.Fragment>
  );
};

import * as React from 'react';
import * as ModelTypes from '@app/Revision/model_types';
import { xirmodel2topomodel } from '@app/Revision/graph';
import { ForceGraph3D, ForceGraph2D } from 'react-force-graph';
import { GraphItemDetails } from '@app/Revision/GraphItemDetails';

import {
  Stack,
  StackItem,
  Card,
  CardBody,
  CardTitle,
  Split,
  SplitItem,
  Radio,
  Slider,
  Text,
  Drawer,
  DrawerPanelContent,
  DrawerContent,
  DrawerHead,
  DrawerActions,
  DrawerCloseButton,
  DrawerContentBody,
} from '@patternfly/react-core';

type TopologyViewProps = {
  model: ModelTypes.XirModel;
};

const TopologyView: React.FunctionComponent<TopologyViewProps> = ({ model }) => {
  const [topoView, setTopoView] = React.useState<ModelTypes.TopoView>(ModelTypes.TopoView.Node);
  const [nodeSize, setNodeSize] = React.useState(3);
  const [fontScale, setFontScale] = React.useState(20);
  const [is2D, setIs2D] = React.useState(true);
  const [itemDetails, setItemDetails] = React.useState('');

  const fgRef = React.useRef();
  const itemDetailRef = React.useRef();
  const height = 1200,
    width = 1000;

  const [nodeC, lanC, socketC] = ['green', 'grey', 'red'];

  // const nodeColor = ({ type }) => {
  //   switch (type) {
  //     case ModelTypes.NodeType.Node:
  //       return nodeC;
  //     case ModelTypes.NodeType.LAN:
  //       return lanC;
  //     case ModelTypes.NodeType.Socket:
  //       return socketC;
  //   }
  //   return nodeC;
  // };

  const drawNode = ({ x, y, type }, ctx) => {
    switch (type) {
      case ModelTypes.NodeType.Node:
        ctx.fillStyle = nodeC;
        ctx.beginPath();
        ctx.arc(x, y, nodeSize, 0, 2 * Math.PI, false);
        ctx.fill();
        break;
      case ModelTypes.NodeType.LAN:
        ctx.fillStyle = lanC;
        ctx.beginPath();
        ctx.fillRect(x - nodeSize / 2, y - nodeSize / 2, nodeSize, nodeSize);
        break;
      case ModelTypes.NodeType.Socket:
        ctx.fillStyle = socketC;
        ctx.beginPath();
        ctx.moveTo(x, y - nodeSize);
        ctx.lineTo(x - nodeSize, y + nodeSize);
        ctx.lineTo(x + nodeSize, y + nodeSize);
        ctx.fill();
    }
  };

  const onNodeLabel = (n) => {
    let [name, metal] = ['', ''];

    switch (n.type) {
      case ModelTypes.NodeType.Node:
        name = n.label;
        metal = n.xir.metal?.value ? n.xir.metal?.value : 'false';
        break;
      case ModelTypes.NodeType.LAN:
        name = n.id;
        metal = 'N/A';
        break;
      case ModelTypes.NodeType.Socket:
        name = n.label;
        metal = 'N/A';
        break;
    }

    return `
            <table>
                <style>
                table, th, td {
                    padding: 5px;
                }
                tr {
                    border-bottom: 1px solid #ddd;
                }
                </style>
                <tr>
                    <th>Name</th>
                    <th>Metal</th>
                </tr>
                <tr>
                    <td>${name}</td>
                    <td>${metal}</td>
                </tr>
            </table>
        `;
  };

  const onItemDetailExpand = () => {
    itemDetailRef.current && itemDetailRef.current.focus();
  };

  // based heavily on https://github.com/usc-isi-i2/kgtk-browser/blob/main/app/src/components/ClassGraphViz.js
  const renderNodeCanvasObject = React.useCallback(
    (node, ctx, globalScale) => {
      const label = node.label;
      const fontSize = fontScale / globalScale;
      ctx.font = `${fontSize}px Sans-Serif`;
      const textWidth = ctx.measureText(label).width;
      const bckgDimensions = [textWidth, fontSize].map((n) => n + fontSize * 0.2); // some padding

      ctx.fillStyle = 'rgba(255, 255, 255, 0.75)';
      ctx.fillRect(node.x - bckgDimensions[0] / 2, node.y - (nodeSize + 5) - bckgDimensions[1] / 2, ...bckgDimensions);

      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';

      ctx.fillStyle = 'black';
      ctx.fillText(label, node.x - (nodeSize + 2), node.y - (nodeSize + 2));

      drawNode(node, ctx);

      node.__bckgDimensions = bckgDimensions; // to re-use in nodePointerAreaPaint
    },
    [nodeSize, fontScale]
  );

  const onNodeClick = React.useCallback(
    (n, e) => {
      setItemDetails(n);
      e.stopPropagation();
    },
    [fgRef]
  );

  const onLinkClick = React.useCallback(
    (l) => {
      setItemDetails(l);
    },
    [fgRef]
  );

  const panelContent = (
    <DrawerPanelContent>
      <DrawerHead>
        <span tabIndex={itemDetails ? 0 : -1} ref={itemDetailRef}>
          <GraphItemDetails details={itemDetails} />
        </span>
        <DrawerActions>
          <DrawerCloseButton onClick={() => setItemDetails('')} />
        </DrawerActions>
      </DrawerHead>
    </DrawerPanelContent>
  );

  return (
    <React.Fragment>
      <Split>
        <SplitItem>
          <Stack>
            <StackItem>
              <Card>
                <CardTitle>Revision Details</CardTitle>
                <CardBody>ID: {model.id}</CardBody>
              </Card>
            </StackItem>
            <StackItem>
              <Card>
                <CardTitle>Model View</CardTitle>
                <CardBody>
                  <Radio
                    id="radio-node"
                    isChecked={topoView == ModelTypes.TopoView.Node}
                    name="nodeview"
                    onChange={() => setTopoView(ModelTypes.TopoView.Node)}
                    aria-label={'Nodes'}
                    label={'Nodes'}
                  />
                  <Radio
                    id="radio-lannode"
                    isChecked={topoView == ModelTypes.TopoView.LanNode}
                    name="nodeview"
                    onChange={() => setTopoView(ModelTypes.TopoView.LanNode)}
                    aria-label={'Nodes & LANs'}
                    label={'Nodes & LANs'}
                  />

                  <Radio
                    id="radio-link"
                    isChecked={topoView == ModelTypes.TopoView.Links}
                    name="linkview"
                    onChange={() => setTopoView(ModelTypes.TopoView.Links)}
                    aria-label={'Nodes & Interfaces'}
                    label={'Nodes & Interfaces'}
                  />
                </CardBody>
              </Card>
            </StackItem>
            <StackItem>
              <Card>
                <CardTitle>Topology View</CardTitle>
                <CardBody>
                  <Radio
                    id="radio-2d"
                    isChecked={is2D === true}
                    name="2d"
                    onChange={() => setIs2D(!is2D)}
                    aria-label={'2D'}
                    label={'2D'}
                  />
                  <Radio
                    id="radio-3d"
                    isChecked={is2D === false}
                    name="3D"
                    onChange={() => setIs2D(!is2D)}
                    aria-label={'3D'}
                    label={'3D'}
                  />
                </CardBody>
              </Card>
              <Card>
                <CardTitle>Topology Display</CardTitle>
                <CardBody>
                  <Text>Node Size: {nodeSize}</Text>
                  <Slider value={nodeSize} onChange={setNodeSize} max={10} min={0} hasTooltipOverThumb />
                  <Text>Font Scale: {fontScale}</Text>
                  <Slider value={fontScale} onChange={setFontScale} max={96} min={0} hasTooltipOverThumb />
                </CardBody>
              </Card>
            </StackItem>
          </Stack>
        </SplitItem>
        <SplitItem isFilled>
          <Drawer isExpanded={itemDetails !== ''} onExpand={onItemDetailExpand}>
            <DrawerContent panelContent={panelContent}>
              <DrawerContentBody>
                <div style={{ height: { height }, width: { width } }}>
                  {is2D === true ? (
                    <ForceGraph2D
                      ref={fgRef}
                      graphData={xirmodel2topomodel(model, topoView)}
                      // showNavInfo={true}
                      width={1200}
                      height={1000}
                      nodeLabel={onNodeLabel}
                      nodeCanvasObject={renderNodeCanvasObject}
                      linkLabel="id"
                      onNodeClick={onNodeClick}
                      onLinkClick={onLinkClick}
                    />
                  ) : (
                    <ForceGraph3D
                      ref={fgRef}
                      graphData={xirmodel2topomodel(model, topoView)}
                      showNavInfo={true}
                      width={1200}
                      height={1000}
                      nodeLabel={onNodeLabel}
                      linkLabel="id"
                    />
                  )}
                </div>
              </DrawerContentBody>
            </DrawerContent>
          </Drawer>
        </SplitItem>
      </Split>
    </React.Fragment>
  );
};

export { TopologyView };

import * as React from 'react';
import {
  Alert,
  AlertVariant,
  AlertProps,
  Grid,
  GridItem,
  Card,
  CardBody,
  CardTitle,
  Split,
  SplitItem,
  Panel,
  PanelHeader,
  PanelMain,
  PanelMainBody,
  Divider,
  PageSection,
  Button,
  Tooltip,
  Text,
} from '@patternfly/react-core';
import { Link } from 'react-router-dom';
import { TaskSummary, TaskStatus_StatusType } from '@mergetb/api/mergetb/tech/reconcile/taskstatus';
import { MtzTopology } from '@app/Materialization/MtzTopology';
import { MtzIngresses } from '@app/Materialization/Ingresses';
import { MtzAttachedXDCs } from '@app/Materialization/AttachedXDCs';
import { GetMaterializationsResponse, Materialization, Ingresses } from '@mergetb/api/portal/v1/materialize_types';
import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';

import { useTranslation } from 'react-i18next';
import { toTitleCase } from '@app/lib/util';

type ActiveExperimentsProps = {
  mtzs: GetMaterializationsResponse | undefined;
  addAlert: (title: string, variant: AlertProps['variant']) => void;
  reload: () => void;
};

const ActiveExperiments: React.FC<ActiveExperimentsProps> = ({ mtzs, addAlert, reload }) => {
  const { t } = useTranslation();
  return (
    <Card>
      <CardTitle>{toTitleCase(t('materialized'))} Experiments</CardTitle>
      <CardBody>
        <Grid hasGutter span={6}>
          {mtzs && mtzs.materializations.length === 0 && <Text>You have no active experiments.</Text>}
          {mtzs &&
            mtzs.materializations.map((_, i) => {
              return (
                <GridItem key={i}>
                  <ActiveExperiment
                    mtz={mtzs.materializations[i]}
                    status={mtzs.statuses[i]}
                    ingresses={mtzs.ingresses[i]}
                    addAlert={addAlert}
                    reload={reload}
                  />
                </GridItem>
              );
            })}
        </Grid>
      </CardBody>
    </Card>
  );
};

type ActiveExperimentProps = {
  mtz: Materialization;
  status: TaskSummary;
  ingresses: Ingresses;
  addAlert: (title: string, variant: AlertProps['variant']) => void;
  reload: () => void;
};

const ActiveExperiment: React.FC<ActiveExperimentProps> = ({ mtz, status, ingresses, addAlert, reload }) => {
  const conf = React.useContext(GeneralSettingsContext);
  const mzid = mtz.rid + '.' + mtz.eid + '.' + mtz.pid;
  let av: AlertVariant = AlertVariant.success;
  let plain = false;

  const { t } = useTranslation();

  switch (status.HighestStatus) {
    case TaskStatus_StatusType.Success: {
      av = AlertVariant.success;
      plain = true;
      break;
    }
    case TaskStatus_StatusType.Pending: {
      av = AlertVariant.warning;
      break;
    }
    case TaskStatus_StatusType.Unresponsive: {
      av = AlertVariant.danger;
      break;
    }
    case TaskStatus_StatusType.Deleted: {
      av = AlertVariant.danger;
      break;
    }
    case TaskStatus_StatusType.Processing: {
      av = AlertVariant.warning;
      break;
    }
    case TaskStatus_StatusType.Error: {
      av = AlertVariant.danger;
      break;
    }
  }

  const cols = {
    xdcs: 'Attached XDCs',
    ingresses: 'Ingresses',
  };

  const doDemtz = () => {
    const x = mzid.split('.');
    fetch(conf.api + '/materialize/materialize/' + x[2] + '/' + x[1] + '/' + x[0], {
      method: 'DELETE',
      credentials: 'include',
    })
      .then((response) => {
        if (response.ok) {
          reload();
          addAlert(toTitleCase(t('dematerialize')) + ' ' + mzid + ' process started', 'success');
        }
      })
      .catch((err) => {
        addAlert('Error ' + t('dematerializing') + ' ' + mzid + ': ' + err, 'danger');
      });
  };

  const ttContent = (
    <>
      {toTitleCase(t('dematerializing'))} an experiment will delete or reset nodes and network to a clean state. The
      experiment resources are still reserved for you, but the resources are not active. (To release the resources for
      others to use, you need to {t('relinquish')} the experiment {t('realization')}).
    </>
  );

  return (
    <Card>
      <CardTitle>
        <Split hasGutter>
          <SplitItem>
            <Link to={'/materializations/' + mtz.pid + '/' + mtz.eid + '/' + mtz.rid}>
              <Alert variant={av} isInline isPlain={plain} title={mzid} />
            </Link>
          </SplitItem>
          <SplitItem isFilled />
          <SplitItem>
            <Tooltip content={ttContent}>
              <Button isSmall variant="secondary" onClick={() => doDemtz()}>
                {toTitleCase(t('dematerialize'))}
              </Button>
            </Tooltip>
          </SplitItem>
        </Split>
      </CardTitle>
      <CardBody>
        <Split hasGutter>
          <SplitItem>
            <MtzTopology mtz={mtz} />
          </SplitItem>
          <SplitItem isFilled>
            <MtzAttachedXDCs mtz={mtz} />
          </SplitItem>
          <SplitItem isFilled>
            <MtzIngresses ings={ingresses} />
          </SplitItem>
        </Split>
      </CardBody>
    </Card>
  );
};

export { ActiveExperiments };

import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { RegistrationFlow, UpdateRegistrationFlowBody } from '@ory/client';
import { Alert, AlertGroup, LoginPage, ListVariant, EmptyState, EmptyStateIcon, Spinner } from '@patternfly/react-core';
import ory, { sdkError } from '@app/lib/OrySdk';
import { UserAuthCard } from '@ory/elements';
import { AuthContext } from '@app/lib/AuthProvider';

import launchLogo from '@app/bgimages/launch-logo.png';
import bgImage1200 from '@app/bgimages/bg-1200.png';
import bgImage768 from '@app/bgimages/bg-768.png';
import bgImage768_2x from '@app/bgimages/bg-768.png';
import bgImage576 from '@app/bgimages/bg-576.png';
import bgImage576_2x from '@app/bgimages/bg-576.png';

const Register: React.FunctionComponent = () => {
  const [flow, setFlow] = React.useState<RegistrationFlow>();
  const [regError, setRegError] = React.useState<string>('');

  const { setSession } = React.useContext(AuthContext);

  const params = new URLSearchParams(document.location.search);
  const loginChallenge = params.get('login_challenge');

  const history = useHistory();

  // Get the flow based on the flowId in the URL (.e.g redirect to this page after flow initialized)
  const getFlow = React.useCallback(
    (flowId: string) =>
      ory
        // the flow data contains the form fields, error messages and csrf token
        .getRegistrationFlow({ id: flowId })
        .then(({ data: flow }) => setFlow(flow))
        .catch(sdkErrorHandler),
    []
  );

  // initialize the sdkError for generic handling of errors
  const sdkErrorHandler = sdkError(getFlow, setFlow, '/registration', true);

  // create a new registration flow
  const createFlow = () => {
    ory
      // we don't need to specify the return_to here since we are building an SPA. In server-side browser flows we would need to specify the return_to
      .createBrowserRegistrationFlow({
        ...(loginChallenge && { loginChallenge: loginChallenge }),
      })
      .then(({ data: flow }) => {
        // Update URI query params to include flow id
        const params = new URLSearchParams({ ['flow']: flow.id });
        history.replace({ pathname: location.pathname, search: params.toString() });
        // Set the flow data
        setFlow(flow);
      })
      .catch(sdkErrorHandler);
  };

  const onRegistrationSubmit = (body: UpdateRegistrationFlowBody) => {
    // something unexpected went wrong and the flow was not set
    if (!flow) return history.push('/registration', { replace: true });

    ory
      .updateRegistrationFlow({
        flow: flow.id,
        updateRegistrationFlowBody: body,
      })
      .then(({ data }) => {
        // we don't do verification at the momment.
        // if ('continue_with' in data && 'continue_with' !== null) {
        //   for (const cw of data.continue_with ?? []) {
        //     if (cw.action === 'show_verification_ui') {
        //       const search = new URLSearchParams();
        //       search.set('flow', cw.flow.id);
        //       history.push(
        //         {
        //           pathname: '/verification',
        //           search: search.toString(),
        //         },
        //         { replace: true }
        //       );
        //       return;
        //     }
        //   }
        // } else {
        if (data.session) {
          setSession(data.session);
          history.push('/userinfo');
          //  }
        }
      })
      .catch(sdkErrorHandler);
  };

  // create the flow
  React.useEffect(() => {
    // we might redirect to this page after the flow is initialized, so we check for the flowId in the URL
    const flowId = params.get('flow');
    // the flow already exists
    if (flowId) {
      getFlow(flowId).catch(createFlow); // if for some reason the flow has expired, we need to get a new one
      return;
    }
    // we assume there was no flow, so we create a new one
    createFlow();
  }, []);

  const images = {
    lg: bgImage1200,
    sm: bgImage768,
    sm2x: bgImage768_2x,
    xs: bgImage576,
    xs2x: bgImage576_2x,
  };

  return (
    <React.Fragment>
      {regError !== '' && (
        <AlertGroup isToast>
          <Alert variant="danger" title={regError} />
        </AlertGroup>
      )}
      <LoginPage
        loginTitle={''}
        // footerListItems={footerListItems}
        footerListVariants={ListVariant.inline}
        brandImgSrc={launchLogo}
        brandImgAlt="Merge Launch"
        textContent={
          "Once you have registered, you will need to contact a Merge Portal operator or your organization's Portal adminstrator to initialize and activate your account. Until your account is approved you will be able to login, but have access to no Merge resources."
        }
        backgroundImgSrc={images}
      >
        {(() => {
          if (flow?.id) {
            return (
              <UserAuthCard
                title={'Register for an Identity Account'}
                flowType={'registration'}
                onSubmit={({ body }) => onRegistrationSubmit(body as UpdateRegistrationFlowBody)}
                flow={flow}
              />
            );
          }
          return (
            <EmptyState>
              <EmptyStateIcon variant="container" component={Spinner} />
              Contacting Authorization Server
            </EmptyState>
          );
        })()}
      </LoginPage>
    </React.Fragment>
  );
};

export { Register };

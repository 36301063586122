export enum NodeType {
  Node = 'node',
  Socket = 'socket',
  LAN = 'LAN',
}

export type Node = {
  id: string | undefined;
  label: string | undefined;
  type: NodeType;
  xir?: unknown;
};

export type Link = {
  id?: string;
  source: string;
  target: string;
  label: string | undefined;
  xir?: unknown;
};

export type TopoModel = {
  nodes: Node[];
  links: Link[];
};

export enum TopoView {
  Node = 'node',
  LanNode = 'lannode',
  Links = 'links',
}

export type XirModel = {
  id: string;
  nodes: unknown;
  links: unknown;
  parameters?: unknown;
};

export type point = {
  x: number;
  y: number;
};

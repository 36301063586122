import * as React from 'react';
import { useParams } from 'react-router-dom';
import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import { useFetch } from 'use-http';

import {
  PageSection,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Spinner,
  Bullseye,
  Alert,
  Grid,
  GridItem,
} from '@patternfly/react-core';

import { headerCol, sortable } from '@patternfly/react-table';
import { ActionList } from '@app/lib/ActionList';
import { DetailEntry, DetailsTable } from '@app/lib/DetailsTable';
import { Link } from 'react-router-dom';
import { TaskStatusTable } from '@app/lib/TaskStatusTable';

import { useTranslation } from 'react-i18next';
import { toTitleCase } from '@app/lib/util';

const Realization: React.FunctionComponent = () => {
  const { pid, eid, rid } = useParams();
  const conf = React.useContext(GeneralSettingsContext);
  const { t } = useTranslation();

  const options = { credentials: 'include' };
  const url = conf.api + '/realize/realizations/' + pid + '/' + eid + '/' + rid;

  const { loading, error, data } = useFetch(url, options, []);

  const st_url = url + '?statusMS=-1';
  const st_getter = (data) => {
    return data.status;
  };

  const crumbs = (
    <PageSection>
      <Breadcrumb>
        <BreadcrumbItem to="/project">Projects</BreadcrumbItem>
        <BreadcrumbItem to={'/project/' + pid}>{pid}</BreadcrumbItem>
        <BreadcrumbItem to="/experiment">Experiments</BreadcrumbItem>
        <BreadcrumbItem to={'/project/' + pid + '/experiment/' + eid}>{eid}</BreadcrumbItem>
        <BreadcrumbItem to="/realizations">{toTitleCase(t('realization'))}</BreadcrumbItem>
        <BreadcrumbItem>{rid}</BreadcrumbItem>
      </Breadcrumb>
    </PageSection>
  );

  const details: Array<DetailEntry> = data?.result?.realization
    ? [
        { label: 'Creator', value: data.result.realization.creator },
        {
          label: 'Created',
          value: data.result.realization.created == null ? '' : new Date(data.result.realization.created).toUTCString(),
        },
        {
          label: 'Expires',
          value:
            data.result.realization.expires == '1970-01-01T00:00:00Z' || data.result.realization.expires == null
              ? 'Never'
              : new Date(data.result.realization.expires).toUTCString(),
        },
        {
          label: 'Revision',
          value: (
            <Link
              to={
                '/model/' +
                data.result.realization.pid +
                '/' +
                data.result.realization.eid +
                '/' +
                data.result.realization.xhash
              }
            >
              {data.result.realization.xhash.substring(0, 8)}
            </Link>
          ),
        },
        { label: 'Infrapod Server', value: data.result.realization.infranet.InfrapodServer },
      ]
    : [];

  return (
    <React.Fragment>
      {crumbs}
      <PageSection>
        <Grid hasGutter>
          <GridItem>
            <Card id={rid + '-card-id'}>
              <CardHeader id={rid + '-cardheader-id'}>
                <CardTitle>{toTitleCase(t('realization'))} Node Details</CardTitle>
              </CardHeader>
              <CardBody>
                {error && !data && (
                  <Alert variant="danger" title="Error">
                    Error loading
                  </Alert>
                )}
                {error && data && data.hasOwnProperty('message') && (
                  <Alert variant="danger" title="Response Error">
                    <pre>{JSON.stringify(data, null, 2)}</pre>
                  </Alert>
                )}
                {loading && (
                  <Bullseye>
                    <Spinner size="sm" />
                  </Bullseye>
                )}
                {data && data.hasOwnProperty('result') && (
                  <>
                    <RealizationNodes rlz={data.result.realization} diag={data.result.diagnostics} active={false} />
                  </>
                )}
              </CardBody>
            </Card>
          </GridItem>
          <GridItem>
            <Card id={rid + '-rlzdeets-id'}>
              <CardHeader id={rid + '-rlzdeedzhdr'}>
                <CardTitle>{toTitleCase(t('realization'))} Details</CardTitle>
              </CardHeader>
              <CardBody>{details.length > 0 && <DetailsTable label="rlzdetails" entries={details} />}</CardBody>
            </Card>
          </GridItem>
          <GridItem>
            <Card id="statusCard">
              <CardHeader>
                <CardTitle id="statusCardTitle">Status</CardTitle>
              </CardHeader>
              <CardBody>
                <TaskStatusTable
                  kind={rid + '-tst'}
                  url={st_url}
                  getter={st_getter}
                  ongoingfrequency={2000}
                  completedfrequency={60000}
                  scalingfrequency={1.0 / 10.0}
                />
              </CardBody>
            </Card>
          </GridItem>
        </Grid>
      </PageSection>
    </React.Fragment>
  );
};

type RlzNodesProps = {
  rlz: unknown;
  diag: unknown;
  active: boolean;
};

const RealizationNodes: React.FunctionComponent<RlzNodesProps> = ({ rlz, diag, active }) => {
  // if it's complete, print the regular list
  if (rlz.complete) {
    const cells = [
      { title: 'Name', cellTransforms: [headerCol()], transforms: [sortable] },
      { title: 'Experiment Addresses' },
      { title: 'Infranet Address' },
      { title: 'Kind' },
      { title: 'Resource' },
      { title: 'Image' },
      { title: 'Facility' },
    ];

    const formatAddrs = (sockets) => {
      let val = [];
      sockets.forEach((s) => {
        val = [...val, ...s.addrs];
      });
      return val.join(', ');
    };

    const rows = Object.keys(rlz.nodes).map((n) => [
      rlz.nodes[n].node.id,
      formatAddrs(rlz.nodes[n].node.sockets),
      rlz.nodes[n].infranetAddr,
      rlz.nodes[n].kind,
      rlz.nodes[n].resource.id,
      rlz.nodes[n].node.image.value,
      rlz.nodes[n].facility,
    ]);

    const actions = [
      { title: 'Console', disabled: true },
      { title: 'Reboot', disabled: true },
    ];

    return <ActionList kind="Realization" columns={cells} rows={rows} actions={active ? actions : []} />;
  }

  // otherwise, print the diagnostics
  const cells = [
    { title: 'Level', cellTransforms: [headerCol()], transforms: [sortable] },
    { title: 'Message' },
    { title: 'Host' },
    { title: 'Guest' },
  ];

  const rows = diag.value.map((v) => [v.level, v.message, v.host, v.guest]);

  return <ActionList kind="Diagnostics" columns={cells} rows={rows} actions={active ? actions : []} />;
};

export { Realization, RealizationNodes };

import * as React from 'react';
import {
  PageSection,
  Title,
  Breadcrumb,
  BreadcrumbItem,
  Split,
  SplitItem,
  Button,
  Alert,
  AlertGroup,
  AlertActionCloseButton,
  Text,
} from '@patternfly/react-core';
import { Link } from 'react-router-dom';
import { ActionList } from '@app/lib/ActionList';
import { sortable, headerCol } from '@patternfly/react-table';
import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import { AuthContext } from '@app/lib/AuthProvider';
import { ColorStringFromStatus, LocalizeDate } from '@app/lib/TaskStatusUtils';
import { RebootMaterializationMode, RebootMaterializationRequest } from '@mergetb/api/portal/v1/materialize_types';

import { useTranslation } from 'react-i18next';
import { toTitleCase } from '@app/lib/util';

const Materializations: React.FunctionComponent = () => {
  var userview_last = (localStorage.getItem('userview') ?? true) == true;

  const conf = React.useContext(GeneralSettingsContext);
  const [reload, setReload] = React.useState(false);
  const [alerts, setAlerts] = React.useState([]);
  const { identity } = React.useContext(AuthContext);
  const username = identity?.traits.username;

  const [viewLabel, setViewLabel] = React.useState('View ' + (userview_last ? 'All' : 'Own'));
  const [userView, setUserView] = React.useState(userview_last);

  const { t } = useTranslation();

  const columns = [
    { title: toTitleCase(t('materialization')), cellTransforms: [headerCol()], transforms: [sortable] },
    { title: 'Metal Nodes' },
    { title: 'Virtual Nodes' },
    { title: 'Links' },
    { title: 'Ingresses' },
    { title: 'Created' },
    { title: 'Last Updated' },
    { title: 'Status' },
  ];

  const mapper = (json) => {
    return json.materializations.map((x, i) => [
      {
        title: (
          <Link key={i} to={'/materializations/' + x.pid + '/' + x.eid + '/' + x.rid}>
            {x.rid}.{x.eid}.{x.pid}
          </Link>
        ),
        props: {
          component: 'th',
          text: x.rid + '.' + x.eid + '.' + x.pid,
        },
      },
      x.numMetal,
      x.numVirtual,
      x.numLinks,
      <>
        {json.ingresses &&
          json.ingresses[i].ingresses.map((ing, ing_i) => (
            <Text component="h4" key={ing_i}>
              {ing.hostname}:{ing.hostport}
            </Text>
          ))}
      </>,
      LocalizeDate(json.statuses[i].FirstUpdated),
      LocalizeDate(json.statuses[i].LastUpdated),
      ColorStringFromStatus(json.statuses[i].HighestStatus, json.statuses[i].HighestStatus),
    ]);
  };

  const actions = React.useMemo(() => {
    // helper function to reboot mz
    const doMzReboot = (mzid: string, mode: RebootMaterializationMode, message: string) => {
      const ids = mzid.split('.');
      const pid = ids[2];
      const eid = ids[1];
      const rid = ids[0];

      const req = RebootMaterializationRequest.fromJSON({
        project: pid,
        experiment: eid,
        realization: rid,
        allNodes: true,
        mode: mode,
      });

      fetch(conf.api + '/materialize/reboot/' + pid + '/' + eid + '/' + rid, {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(req),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            return response.text().then((text) => {
              throw new Error(text);
            });
          }
        })
        .then((json) => {
          addAlert(message, '', 'success');
          setReload(!reload);
        })
        .catch((error) => {
          console.log('got error:', error);
          addAlert('Reboot Error', error.message, 'danger');
        });
    };

    return [
      {
        title: toTitleCase(t('dematerialize')),
        onClick: (event, rowId, rowData) => {
          const ids = rowData[0].props.text.split('.');
          const pid = ids[2];
          const eid = ids[1];
          const rid = ids[0];

          fetch(conf.api + '/materialize/materialize/' + pid + '/' + eid + '/' + rid, {
            method: 'DELETE',
            credentials: 'include',
          })
            .then((response) => {
              if (response.ok) {
                return response.json();
              } else {
                return response.text().then((text) => {
                  throw new Error(text);
                });
              }
            })
            .then((json) => {
              addAlert('Dematerialization ' + rid + '.' + eid + '.' + pid + ' started.', '', 'success');
              setReload(!reload);
            })
            .catch((error) => {
              console.log('got error:', error);
              addAlert('Dematerialize Error', error.message, 'danger');
            });
        },
      },
      {
        isSeparator: true,
      },
      {
        title: 'Warm Reboot',
        onClick: (event, rowID, rowData) => {
          const mzid = rowData[0].props.text;
          doMzReboot(mzid, RebootMaterializationMode.Reboot, mzid + ': Warm Reboot requested');
        },
      },
      {
        title: 'Power Cycle',
        onClick: (event, rowID, rowData) => {
          const mzid = rowData[0].props.text;
          doMzReboot(mzid, RebootMaterializationMode.Cycle, mzid + ': Power Cycle requested');
        },
      },
      {
        title: 'Reimage',
        onClick: (event, rowID, rowData) => {
          const mzid = rowData[0].props.text;
          doMzReboot(mzid, RebootMaterializationMode.Reimage, mzid + ': Reimage requested');
        },
      },
    ];
  }, [conf.api]);

  const toggleView = () => {
    setViewLabel('View ' + (userView === false ? 'All' : 'Own'));
    localStorage.setItem('userview', userView ? '0' : '1');
    setUserView(!userView);
    setReload(!reload);
  };

  const crumbs = (
    <PageSection>
      <Breadcrumb>
        <BreadcrumbItem>User</BreadcrumbItem>
        <BreadcrumbItem to={'/user/' + username}>{username}</BreadcrumbItem>
        <BreadcrumbItem>{toTitleCase(t('materializations'))}</BreadcrumbItem>
      </Breadcrumb>
    </PageSection>
  );

  const header = (
    <PageSection>
      <Split>
        <SplitItem>
          <Title headingLevel="h1" size="lg">
            {toTitleCase(t('materializations'))}
          </Title>
        </SplitItem>
        <SplitItem isFilled />
        <SplitItem>
          <Button variant="control" aria-label={viewLabel} onClick={toggleView}>
            {viewLabel}
          </Button>
        </SplitItem>
      </Split>
    </PageSection>
  );

  const addAlert = (t, m, v) => {
    setAlerts((prev) => [...prev, { title: t, message: m, variant: v, key: new Date().getTime() }]);
  };

  const removeAlert = (key) => {
    setAlerts([...alerts.filter((el) => el.key !== key)]);
  };

  const notifications = (
    <AlertGroup isToast>
      {alerts.map((a, i) => (
        <Alert
          isExpandable={a.message !== ''}
          variant={a.variant}
          title={a.title}
          key={a.key}
          timeout={8000}
          actionClose={
            <AlertActionCloseButton
              title={a.title}
              variantLabel={`${a.variant} alert`}
              onClose={() => removeAlert(a.key)}
            />
          }
        >
          {a.message}
        </Alert>
      ))}
    </AlertGroup>
  );

  let url = conf.api + '/materializev2/materializations';
  if (userView === false) {
    url += '?filter=ByAll';
  }

  return (
    <React.Fragment>
      {alerts.length !== 0 && notifications}
      {crumbs}
      {header}
      <PageSection>
        <ActionList
          kind={toTitleCase(t('materializations'))}
          columns={columns}
          url={url}
          actions={actions}
          mapper={mapper}
          reload={reload}
        />
      </PageSection>
    </React.Fragment>
  );
};

export { Materializations };
